import React, {useEffect} from 'react';
import style from './media-player-style.module.scss';

import backgroundAudioMP3 from '../../sound/5sec.mp3';
import backgroundAudioWAV from '../../sound/5sec.wav';
import backgroundAudioOGG from '../../sound/5sec.ogg';
import soundTest from '../../sound/soundsTest_2.mp3';


function MediaPlayer() {
  // const audio = new Audio(soundTest);
  const audio = new Audio(backgroundAudioMP3);
  audio.loop = true;
  audio.volume = 1;
  useEffect(() => {
    document.addEventListener('click', () => {
      console.log('Playing Background Sound')
      audio.play();
    }, { once: true } )
  })



  return (
    <div className={`${style.audioWrap} `}>
      {/* <audio className='audioPlayer' > </audio> */}
      {/* <audio id="audioPlayer" autoPlay mute="true" loop src="sound/5sec.mp3" preload="auto">
      <source src={soundTest} type="audio/mpeg" />
        <source src={backgroundAudioMP3} type="audio/mpeg" />
        <source src={backgroundAudioWAV} type="audio/wav" />
        <source src={backgroundAudioOGG} type="audio/ogg" />
      </audio> */}
    </div>
  );
}

export default MediaPlayer;
