import React from 'react';
import stye from './overlay-wrap-style.module.scss';



function Overlay(Props) {
   // console.log('>>> Overlay: ', Props);

   let visibilityClass = '';
   if (Props.visibility === 1) {
      visibilityClass = stye.show;
   } else {
      // visibilityClass = '';
   }

  return (
    <div className={`${stye.wrapper} ${visibilityClass}`}>
      {Props.children}
    </div>
  );
}

export default Overlay;
