import React, { useState, useMemo, useRef } from 'react';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBinoculars } from '@fortawesome/free-solid-svg-icons';
import ScoreSheet from '../score-sheet/score-sheet';
import {
   createColumnHelper,
   flexRender,
   getCoreRowModel,
   useReactTable,
   getPaginationRowModel,
} from '@tanstack/react-table'
import DBHandler from '../../javascript/index-db/db-handler';
import { useIsVisible } from 'react-is-visible'
import { Match } from '../../javascript/sport/class/coreSport';
import { ConfirmModel } from '../../javascript/utils/utils';
import { convertDate_from_timestamp } from '../../javascript/utils/date-control';

import style from './match-history-style.module.scss';

function MatchHistory(Props) {
   const wrapper = useRef();
   const dbHandler = DBHandler();
   const columnHelper = createColumnHelper();
   const [data, setData] = useState(() => []);
   const [showTable, setShowTable] = useState(true);
   const columns = [
      columnHelper.accessor('html', {
         header: () => 'Date',
         footer: info => info.column.id,
      }),
      columnHelper.accessor('team_a', {
         header: () => 'Player / Team A',
         footer: info => info.column.id,
      }),
      columnHelper.accessor('team_b', {
         header: () => 'Player / Team B',
         footer: info => info.column.id,
      }),
      columnHelper.accessor('score', {
         header: () => 'Score',
         footer: info => info.column.id,
      }),
      columnHelper.accessor('action', {
         header: () => 'Scoresheet',
         footer: info => info.column.id,
      }),

   ]
   const table = useReactTable({
      data,
      columns,
      getCoreRowModel: getCoreRowModel(),
      getPaginationRowModel: getPaginationRowModel(),
   });


   const get_match_list = async () => {
      const tableData = [];
      const matchList = await dbHandler.loadAllMatches();

      for (let a = 0; a < matchList.length; a++) {
         const lastGame = matchList[a].games[matchList[a].games.length - 1];

         if (lastGame.state === 'complete') {
            const matchTest = new Match(matchList[a]);
            const score = matchTest.match_score_jsx;
            const date = convertDate_from_timestamp(lastGame.end_time);
            const dateFormat = dayjs(date).format('DD MMM');
            const time = dayjs(date).format('HH:MM');

            tableData.push({
               ROW_ID: matchTest.id,
               html: (<>{dateFormat}<br/>{time}</>),
               team_a: matchTest.players.teams[0].name,
               team_b: matchTest.players.teams[1].name,
               score: score,
               action: (<div> <FontAwesomeIcon icon={faBinoculars} /> </div>)
            });
         }
      }
      setData(tableData);
   }

   useMemo(() => { get_match_list(); }, [useIsVisible(wrapper)]);

   const rowClick = async (match_id) => {
      const match = await dbHandler.loadMatch(match_id);
      const matchObj = new Match(match);
      const lastGame = matchObj.games[matchObj.games.length - 1];
      const date = convertDate_from_timestamp(lastGame.end_time);
      const HTML = (
         <div className={style.matchDetails} >
            <div><b>Match Code: </b>  {matchObj.match_code} </div>
            <div><b>Date: </b>  {date} </div>
            <div><b>Uploaded: </b>  {'No'} </div>
            <div className={style.matchDetails_ScoreSheet} >
               <ScoreSheet match_id={match_id} />
            </div>
         </div>
      )

      ConfirmModel({
         title: `Match Details`,
         html: HTML,
         confirmButtonText: 'Close',
         showCancelButton: false,
         allowOutsideClick: true,
         showCloseButton: true,
         width: '90vw',
         // showCancelButton: true,
      });
   }






   return (
      <div className={style.wrap} ref={wrapper}>
         {showTable ? (
            <table className={style.reportTable}>
               <thead>
                  {table.getHeaderGroups().map(headerGroup => (
                     <tr key={headerGroup.id}>
                        {headerGroup.headers.map(header => (
                           <th key={header.id}>
                              {header.isPlaceholder
                                 ? null
                                 : flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                 )}
                           </th>
                        ))}
                     </tr>
                  ))}
               </thead>
               <tbody>
                  {table.getRowModel().rows.map(row => (
                     <tr key={row.id} onClick={() => { rowClick(row.original.ROW_ID) }} className={Props.selectedROWID === row.original.ROW_ID ? style.activeRow : ''} >
                        {row.getVisibleCells().map(cell => (
                           <td key={cell.id}>
                              {(() => {
                                 if (cell.column.id === 'score' || cell.column.id === 'action' || cell.column.id === 'html' ) {
                                    return (cell.getValue())
                                 }
                                 return flexRender(cell.column.columnDef.cell, cell.getContext())
                              })()}
                           </td>
                        ))}
                     </tr>
                  ))}
               </tbody>
            </table>
         ) : (<></>)}
         {data.length > 10 ? (
            <div className={style.formControl}>
               <div>
                  <button className="border rounded p-1" onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage()} >{'<<'}</button>
                  <button className="border rounded p-1" onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()} >{'<'}</button>
                  <button className="border rounded p-1" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()} >{'>'}</button>
                  <button className="border rounded p-1" onClick={() => table.setPageIndex(table.getPageCount() - 1)} disabled={!table.getCanNextPage()} >{'>>'}</button>
               </div>
               <div className={style.formPageNu}>
                  <span className={style.items_center + ' ' + style.flex_center} >
                     <div>Page: </div>
                     <strong > {table.getState().pagination.pageIndex + 1} of{' '} {table.getPageCount()} </strong>
                  </span>
                  <div>
                     <select className='' value={table.getState().pagination.pageSize} onChange={e => { table.setPageSize(Number(e.target.value)) }}>
                        {[1, 5, 10, 25, 50, 100].map(pageSize => (<option key={pageSize} value={pageSize}> Show {pageSize} </option>))}
                     </select>
                  </div>
               </div>
            </div>
         ) : (<></>)}
      </div>
   )
}


export default MatchHistory;