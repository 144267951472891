import React, { useContext, useState } from "react";
const AppContext = React.createContext();
const AppContextUpdate = React.createContext();


export function GetAppContext () {
   return useContext(AppContext);
}

export function GetAppContextUpdate () {
   return useContext(AppContextUpdate);
}


export function AppContextProvider({ children }) {
   const [testValue, setTestValue] = useState('test');

   const updateTestValue = () => {
      console.log('>>> updateTestValue <<<');

      setTestValue('')
   }


   return (
      <AppContext.Provider value={testValue}>
         <AppContextUpdate.Provider value={updateTestValue}>
            {children}
         </AppContextUpdate.Provider>
      </AppContext.Provider>
   )
} 



// Not use just yet -- Maybe Delete
//  https://www.youtube.com/watch?v=5LrDIWkK_Bc&t=607s


