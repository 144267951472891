import dayjs from "dayjs";



const convertDate_from_timestamp = (timestamp) => {
   const date = dayjs(timestamp);
   
   if (date.isValid()) {
      const dateFormate = date.format('YYYY-MM-DD HH:mm');
      return dateFormate;
   }
   return '';
}



export {
   convertDate_from_timestamp
};