import React, { useEffect, useState } from 'react';
import { GLOBAL_VARS } from '../../javascript/services/globalVars';
import style from './paid-status-style.module.scss';

function PaidStatus(Props) {
  const [wrapClass, setWrapClass] = useState(() => style.wrap);
  useEffect(() => {
    // console.log('>>> UPDATE PAID STATUS <<<: ', Props.state, GLOBAL_VARS.free_app);
    if (Props.state === 1) {
      if (GLOBAL_VARS.free_app) {
        setWrapClass(style.wrap);
      } else {
        setWrapClass(style.wrap_hide);
      }
    } else {
      setWrapClass(style.wrap_hide);
    }
    return () => {
        // cleanup
    }
}, [Props.state, GLOBAL_VARS.free_app])

  return (
    <div  className={wrapClass}>
      FREE
   </div>
  );
}

export default PaidStatus;
