"use strict";
import React, { useEffect, useState } from 'react';
import style from './dropdown-style.module.scss';

function DropDown(Props) {
   const [value, setValue] = useState('');
   const name = Props.name;
   const options = Props.options;

   const onChange = (event) => {
      setValue(event.target.value);
   }

   useEffect(() => { 
      setValue(Props.defaultValue)
   }, [Props]);

   return (<div className={style.wrap}>
      <select name={name} value={value} onChange={onChange} >
         {options.map(o => <option key={o.value} value={o.value}>{o.label}</option>)}
      </select>
      {Props.children}
   </div>);
}

export default DropDown;