import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import MD5 from 'crypto-js/md5';
import LogoImg from '../logo-img/logo-img';
import Auth from '../../javascript/services/auth';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Logout, ConfirmModel } from '../../javascript/utils/utils';
import style from './login-form-style.module.scss';

function LoginForm(Props) {
   const authObj = Auth();
   const [loadingShow, setLoadingShow] = useState('');
   const [loggedIn, setLoggedIn] = useState(false);

   const closeBtn = () => {
      if (typeof Props.commonTriggers != 'undefined') {
         Props.commonTriggers.hide();
         Props.commonTriggers.element((<></>));
      }
   }

   const logoutBtn = async () => {
      const logoutBtn = $('#logoutBtn').attr('sts');
      if (logoutBtn === '') {
         $('#logoutBtn').attr('sts', 'loading');
         setLoadingShow(style.loadingShow);
         const logout_result = await Logout();
         if (logout_result === false) {
            logoutFailure();
         }
         setLoadingShow('');
         $('#logoutBtn').attr('sts', '');
      }
   }

   const loginBtn = () => {
      const userName = $('#loginUsername').val();
      const password = $('#loginPassword').val();
      const loginBtn = $('#loginBtn').attr('sts');
      if (loginBtn === "") {
         if (userName === '') {
            $('#loginUsername')[0].reportValidity();
            return false;
         }
         if (password === '') {
            $('#loginPassword')[0].reportValidity();
            return false;
         }
         $('#loginBtn').attr('sts', 'loading');
         setLoadingShow(style.loadingShow);
         authObj.login({
            username: userName,
            password: MD5(password).toString(),
         }).then((loginResult) => {
            console.log('loginResult: ', loginResult);
            if (loginResult === true) {
               loginSuccess();
            } else {
               let msg = '';
               if (typeof loginResult === 'string') {
                  msg = loginResult;
               }
               loginFailure(msg);
            }
         }).catch((loginError) => {
            console.log('loginError: ', loginError)
            loginFailure('System Error, Please try again later');
         }).finally(() => {
            setLoadingShow('');
            $('#loginBtn').attr('sts', '');
         });
      }
   }
   const loginKeyDown = (e) => {
      if (e.key === 'Enter') {
         loginBtn();
      }
   }
   const loginSuccess = () => {
      closeBtn();
   }
   const loginFailure = (msg = '') => {
      if (msg === 'Connection Error') {
         ConfirmModel({
            icon: 'error',
            title: `Login Failure`,
            text: msg,
            confirmButtonText: 'Ok',
            allowOutsideClick: true,
            showCancelButton: false,
            footer: `<a href="mailto:support@scoringmadeeasy.com">support@scoringmadeeasy.com</a>`
         });
      } else {
         ConfirmModel({
            icon: 'warning',
            title: `Login Failure`,
            text: msg,
            confirmButtonText: 'Ok',
            allowOutsideClick: true,
            showCancelButton: false,
         });
      }
   }

   const logoutFailure = () => {
      ConfirmModel({
         icon: 'error',
         title: `Login Failure`,
         text: 'Error Logging out',
         confirmButtonText: 'Ok',
         allowOutsideClick: true,
         showCancelButton: false,
         footer: `<a href="mailto:support@scoringmadeeasy.com">support@scoringmadeeasy.com</a>`
      });
   }

   useEffect(() => {
      authObj.init().then((result) => {
         setLoggedIn(result);
      });
   });
   return (
      <div className={`${style.loginBox}`}>
         <div className={style.title}>
            Scoring Made Easy
         </div>
         <div className={style.sub_title}>
            Scoring App
         </div>
         {loggedIn ? (
            <div className={style.form}>
               <h2> Logged In</h2>
               <div className={`${style.Btn} ${style.logoutBtn}`} onClick={logoutBtn} id="logoutBtn" sts="" >Logout</div>
               <div className={`${style.loading} ${loadingShow}`}><FontAwesomeIcon icon={faSpinner} spin /></div>
            </div>
         ) : (
            <div className={style.form} >
               <input type="text" name="username" id="loginUsername" placeholder="Username" onKeyDown={loginKeyDown} required />
               <input type="password" name="password" id="loginPassword" placeholder="Password" onKeyDown={loginKeyDown} required />
               <div className={`${style.loginBtn} ${style.Btn}`} onClick={loginBtn} id="loginBtn" sts="" >Login</div>
               <div className={`${style.loading} ${loadingShow}`}><FontAwesomeIcon icon={faSpinner} spin /></div>
               <div className={style.img}> <LogoImg /> </div>
               <div className={style.foot}> www.scoringmadeeasy.com</div>
            </div>
         )
         }
      </div>
   );
}

export default LoginForm;
