import React, { Component } from "react";
import style from './input-wrap-style.module.scss';

class InputWrap extends Component {
   label = (<></>);
   extra_style = '';
   constructor(props) {
      super(props);
      if (typeof props.label != 'undefined' && props.label != null) {
         this.label = (<label>{props.label}</label>);
      }
      if (props.hidden === true) {
         this.extra_style = style.hidden;
      }
   }

   render() {
      return (
         <div className={`${style.inputWrap} ${this.extra_style}`} >
            {this.label}
            {this.props.children}
         </div>
      );
   }
}

export default InputWrap;