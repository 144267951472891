import React, { useEffect, useState } from 'react';
import { faWifi } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GLOBAL_VARS } from '../../javascript/services/globalVars';
import { Loop_Timer } from '../../javascript/utils/utils';
import style from './connection-indicator-style.module.scss';
const watch_timer = new Loop_Timer('connection-indicator-refresh', 5);

function ConnectionIndicator(Props) {
   let [color, setColor] = useState(() => 'red');
   useEffect(() => {
      watch_timer.start(() => {
         // console.log('---------- Watch: ', GLOBAL_VARS.server_connection);
         if (GLOBAL_VARS.server_connection === true) {
            setColor('var(--green)');
         } else {
            setColor('red');
         }
      });
      return () => {
         // >>> Runs on unLoad (Unload runs first if component is already mounted)
         watch_timer.stop();
      };
   }, []);
   return (
      <div className={style.wrap} style={{ color: color }} > <FontAwesomeIcon icon={faWifi} /> </div>
   );
}

export default ConnectionIndicator;