import React from 'react';
import $ from 'jquery';
import RemoteImage from '../remote_img/remote_img';
import { faTablet } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import style from './register_device.style.module.scss';

import Input from '../form/input/input';

function RegisterDevice(Props) {
   const select = (value) => {
      if (typeof Props.select_callback === 'function') {
         Props.select_callback(value);
      }
   }
   const register_device = () => {
      const name = $('#deviceInput').val();
      if (typeof Props.new_device_callback === 'function') {
         Props.new_device_callback(name);
      }
   }

   return (
      <div className={style.wrap}>
         <div className={style.device_container}>
            {typeof Props.show_new_device !== 'undefined' && Props.show_new_device === true ? (<h2>Select Device name:</h2>) : null}
            <div className={style.device_list} >
               {typeof Props.device_list !== 'undefined' ?
                  Props.device_list.map(device => {
                     return (<div className={style.device_wrap} key={device.device_id} onClick={() => { select(device.device_id); }}>
                        <span> <FontAwesomeIcon icon={faTablet} /> {device.name}</span>
                     </div>)
                  }) : null
               }
            </div>
            {typeof Props.show_new_device !== 'undefined' && Props.show_new_device === true ?
               <>
                  <div className={style.new_device_box}>

                     <h2>or</h2>
                     <h2>Register New Device name:</h2>
                     {/* <span> Enter Device Name i.e. Court 1</span> */}
                     <Input className={style.new_device_box_input} id="deviceInput" placeholder={'i.e. Court 1'} />
                     <div className={style.new_device_btn} onClick={register_device}> Register New Device </div>
                  </div>
               </> : null
            }
         </div>

         <div className={style.court_container}>
            {typeof Props.court_details !== 'undefined' ?
               Props.court_details.map(court => {
                  return (<div className={style.court_wrap} onClick={() => { select(court.ccfg_id); }}>
                     <span>{court.court_name}</span>
                     <RemoteImage className={style.img} src={court.court_image.substring(3)} />
                  </div>)
               }) : null
            }
         </div>
      </div>
   );
}

export default RegisterDevice;