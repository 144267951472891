import React from 'react';
import StandardScoreSheet from './standard-score-sheet/standard-score-sheet';

function ScoreSheet(Props) {
   // useEffect(() => {
   //    console.log('>>> Loading Score sheet....');
   // }, [Props.update]); // >> Runs on Mount only

   

   return (
      <div>
         <StandardScoreSheet match_id={Props.match_id}  update={Props.update} ></StandardScoreSheet>
      </div>
   );
}




export default ScoreSheet;