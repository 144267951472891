import React from 'react';
import style from './input-style.module.scss'
function Input(Props) {
   const type = Props.type;
   let value = Props.value;
   const placeholder = Props.placeholder;
   const name = Props.name;
   const class_name = typeof Props.className !== 'undefined'? Props.className:style.wrap;
   return (<div className={class_name}>  <input id={Props.id} type={type} defaultValue={value} name={name} placeholder={placeholder} />  {Props.children} </div>);
}

export default Input;