import React, { Component } from "react";
import style from './radio-select-style.module.scss';

class RadioSelect extends Component {
   options = [];
   name = 'element_name';

   constructor(props) {
      super(props);
      let selectedOption = 0;
      if (typeof props.value != 'undefined') {
         selectedOption = props.value;
      }
      if (typeof props.options != 'undefined') {
         this.options = props.options;
      }
      if (typeof props.name != 'undefined') {
         this.name = props.name;
      }
      this.state = { selectedOption: selectedOption };
   }

   handleOptionChange = changeEvent => {
      this.setState({
         selectedOption: changeEvent.target.value
      });
   };

   render() {
      return (
         <div className={style.radioSelect}>
            {this.options.map((item) => {
               return (<label key={`${item.label}-${item.value}`} className={`${parseInt(this.state.selectedOption) === item.value ? style.radioChecked : ''}`}>
                     <input
                        type="radio"
                        name={this.name}
                        value={item.value}
                        checked={parseInt(this.state.selectedOption) === item.value}
                        onChange={this.handleOptionChange}
                     />
                     {item.label}
                  </label>)
            })}
         </div>
      );
   }
}

export default RadioSelect;