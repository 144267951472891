import React from 'react';
import style from './side-menu-btn-style.module.scss';

function SideMenuBtn(Props) {
   // console.log('>>> SideMenuBtn: ', Props);

   let styleHidden = '';
   let styleSide = style.posLeft;
   if (Props.side === 'right') {
      styleSide = style.posRight;
   } else if (Props.side === 'bottom') {
      styleSide = style.posBottom;
   }
   if (Props.hide === 1) {
      styleHidden = style.hide;
   }

   const onClickCallBack = () => {
      if (typeof Props.onClick === 'function') {
         Props.onClick();
      }
   }

   return (
      <div className={`${style.sideMenuBtnWrap} ${styleSide} ${styleHidden} SideMenuBtn`} >
         <div onClick={onClickCallBack} className={style.sideMenuBtn}>
            {Props.children}
         </div>
      </div>
   );
}

export default SideMenuBtn;