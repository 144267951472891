"use strict";
import React, { useEffect, useState } from 'react';
import style from './slider-style.module.scss';

function Slider(Props) {
   const [value, setValue] = useState(0);
   const [valueDisplay, setValueDisplay] = useState(0);
   const max = Props.max;
   const min = Props.min;
   const name = Props.name;
   const step = Props.step;
   const defaultValue = Props.defaultValue;
   const onChange = (event) => { 
      setValueDisplay(event.target.value); 
      setValue(event.target.value);
   }

   useEffect(() => { 
      setValueDisplay(defaultValue); 
      setValue(defaultValue)
   }, [Props]);
   

   return (<div className={style.wrap}>
      <input type="range" name={name} min={min} max={max} value={value} onChange={onChange} step={step} className={style.slider}></input>
      <span className={style.display}>{valueDisplay}</span>
      {Props.children}
   </div>);
}

export default Slider;