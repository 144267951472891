import React, { useEffect, useState } from 'react';
import style from './score-totals-style.module.scss';
import { SPORT_BASE } from '../../javascript/services/globalVars.js';


function ScoreTotals(Props) {
   // const [match_type, setMatch_type] = useState('A');
   // const [icon_c, setIcon_c] = useState('C');
   // const [icon_d, setIcon_d] = useState('D');
   // const [color_c, setColorC] = useState({ color: 'var(--player-a-color)' });
   // const [color_d, setColorD] = useState({ color: 'var(--player-b-color)' });
   const [icon_a, setIcon_a] = useState('A');
   const [icon_b, setIcon_b] = useState('B');
   const [name_a, setName_a] = useState('Player A');
   const [name_b, setName_b] = useState('Player B');
   const [color_a, setColorA] = useState({ color: 'var(--player-a-color)' });
   const [color_b, setColorB] = useState({ color: 'var(--player-b-color)' });
   const [scoreTotals, setScoreTotals] = useState([{ A: 0, B: 0, Win: 0 }]);

   useEffect(() => {
      const playerObj = SPORT_BASE.sport_object.get_player_settings();
      setColorA({ color: SPORT_BASE.sport_object.scoring.state.match.players.teams[0].team_color });
      setColorB({ color: SPORT_BASE.sport_object.scoring.state.match.players.teams[1].team_color });
      setScoreTotals(SPORT_BASE.sport_object.scoring.state.match.match_score);
      setName_a(playerObj.Match_Players.teams[0].name);
      setName_b(playerObj.Match_Players.teams[1].name);

      // const matchType = SPORT_BASE.sport_object.scoring.state.match.settings.match_type;
      // setMatch_type(matchType);
      
      const { Match_Players } = SPORT_BASE.sport_object.get_player_settings();
      if (Match_Players.teams[0].players.length > 0) {
         const playerA = Match_Players.teams[0].players[0].initial;
         const playerB = Match_Players.teams[1].players[0].initial;
         setIcon_a(playerA);
         setIcon_b(playerB);
         if (Match_Players.teams[0].players.length === 2) {
            setIcon_a('A');
            setIcon_b('B');
            setColorA({ color: 'var(--player-a-color)' });
            setColorB({ color: 'var(--player-b-color)' });
            // const playerC = Match_Players.teams[0].players[1].initial;
            // const playerD = Match_Players.teams[1].players[1].initial;
            // setIcon_c(playerC);
            // setIcon_d(playerD);
            // setColorC({ color: Match_Players.teams[0].players[1].color })
            // setColorD({ color: Match_Players.teams[1].players[1].color })
         }
      }
      
   }, [Props.refresh]);

   return (
      <div className={style.wrap}>
         <div className={style.blockWrap}>
            <div style={color_a} className={style.icon}>{icon_a}</div>
            {/* {match_type === "2"? (<>
               / <div style={color_c} className={style.icon}>{icon_c}</div>
            </>
            ):('')} */}
            <div className={style.name}>{name_a}</div>
            <div className={style.scoreBlock}>
               {scoreTotals.map((s, i) => <div key={`A-${i}`} style={s.Win === 1 ? color_a : {}} className={style.scoreItem}>{s.A}</div>)}
            </div>
         </div>
         <div className={style.blockWrap}>
            <div style={color_b} className={style.icon}>{icon_b}</div>
            {/* {match_type === "2"? (<>
               /<div style={color_d} className={style.icon}>{icon_d}</div>
            </>
            ):('')} */}
            <div className={style.name}>{name_b}</div>
            <div className={style.scoreBlock}>
               {scoreTotals.map((s, i) => <div key={`A-${i}`} style={s.Win === 2 ? color_b : {}} className={style.scoreItem}>{s.B}</div>)}
            </div>
         </div>
      </div>
   );
}

export default ScoreTotals;