import React from 'react';
import style from './side-menu-style.module.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

function SideMenu(Props) {
   // console.log('>>> SideMenu: ', Props)
   // console.log('>>> SideMenu X: ', Props.children)

   let showClass = '';
   let HeadElement = '';
   let BodyElement = '';
   let posClass = style.menuLeft;

   if (Props.state === 1) {
      showClass = style.sideMenuShow;
   }
   // if (Props.allowClose === 1) {
   //    showClass = style.sideMenuShow;
   // }

   if (Props.pos === 'right') {
      posClass = style.menuRight;
   }

   if (typeof Props.specialMenu !== 'undefined') {
      const specialMenu = Props.specialMenu();
      HeadElement = specialMenu.head;
      BodyElement = specialMenu.body;
   }

   const handle_close_btn = () => {
      if (typeof Props.callback === 'function') {
         Props.callback();
      }
   }

   return (
      <div className={`${style.sideMenuWrap} ${posClass} ${showClass}`}>
         <div className={style.sideMenuHead}>
            {HeadElement}
            {Props.allowClose !== false? (<div className={style.closeBtn} onClick={handle_close_btn}><FontAwesomeIcon icon={faXmark} /> </div>):null}
         </div>
         <div className={style.sideMenuBody}> {BodyElement} {Props.children} </div> 
      </div>
   );
}

export default SideMenu;


