import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import style from './loading-wheel-style.module.scss';

function LoadingWheel(Props) {
   return (
      <>
         {Props.show ? (<div className={style.wrap}>
            <div className={style.block}>
               <FontAwesomeIcon icon={faSpinner} spin />
            </div>
         </div>) : null}
      </>
   );
}

export default LoadingWheel;
 