import React from 'react';
import StaticBtnWrap from '../static-btn-wrap/static-btn-wrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeHigh } from '@fortawesome/free-solid-svg-icons';
import { SoundsTest } from '../../../javascript/sport/sport-function';

function BtnSoundCheck(Props) {
   return (
      <StaticBtnWrap style="style_1" onClick={SoundsTest}>
         <FontAwesomeIcon icon={faVolumeHigh} />
         Check
      </StaticBtnWrap>
   );
}

export default BtnSoundCheck;