import React, { useState, useEffect, useRef } from 'react';
import { useIsVisible } from 'react-is-visible';
import LoadingWheel from '../loading-wheel/loading-wheel';
import PushMatchBox from './push-match-box/push-match-box';
import DBHandler from '../../javascript/index-db/db-handler';
import { build_sme_con } from '../../javascript/services/auth';
import { SPORT_BASE } from '../../javascript/services/globalVars';
import { Push_Match } from '../../javascript/sport/class/coreSport';
import { ConfirmModel, Loop_Timer } from '../../javascript/utils/utils';
import { Handle_Game_Change } from '../../javascript/utils/game-utils';
import { API_CONTROL } from '../../javascript/services/api-connection';
import style from './push-match-loader-style.module.scss';
const push_match_loader_timer = new Loop_Timer('push-match_loader-refresh', 10);

const dbHandler = DBHandler();

function PushMatchLoader(Props) {
   const wrapper = useRef();
   const isVisible = useIsVisible(wrapper);
   const [loadingShow, setLoadingShow] = useState(true);
   const [matchData, setMatchData] = useState(() => []);
   const [noMatch, setNoMatch] = useState(() => false);

   useEffect(() => {
      if (isVisible) {
         push_match_loader_timer.stop();
         push_match_loop();
      } else {
         push_match_loader_timer.stop();
         setMatchData([]);
         setLoadingShow(true);
         setNoMatch(false)
      }
   }, [isVisible]);

   const push_match_loop = async () => {
      if (isVisible) {
         console.log('Refreshing Push Matches');
         await load_matches();
         push_match_loader_timer.start(() => {
            push_match_loader_timer.stop();
            push_match_loop();
         });
      }
   }

   const load_matches = () => {
      return new Promise(async (resolve) => {
         try {
            build_sme_con().then(async (sme_con) => {
               const api_control = API_CONTROL({ sme_con: sme_con });
               const sync = await dbHandler.load_sync();
               const club = await dbHandler.load_club();
               const club_id = parseInt(club.club_id);
               const sport_type = SPORT_BASE.sport_name;
               const device_id = parseInt(sync.device_id);
               const push_match_result = await api_control.SME.query.push_match(club_id, sport_type, device_id);


               console.log('Testing Match: ', push_match_result.matches);

               setMatchData(push_match_result.matches);
               setLoadingShow(false);
               setNoMatch(false)
               if (push_match_result.matches.length === 0) {
                  setNoMatch(true)
               }
                  resolve(true);
            });
         } catch (error) {
            resolve(false);
         }
      });
   }

   const select_prompt = (data) => {
      console.log('>>> select_prompt: ', data);

      ConfirmModel({
         title: `Are player names correct?`,
         text: `I will purchase a drink for Tournament Control if I proceed with incorrect player names!`,
         confirmButtonText: '100% YES!',
         denyButtonText: 'Not sure!',
         showDenyButton: true,
         showCancelButton: false,
         allowOutsideClick: false,
      }).then((result) => {
         if (result.isConfirmed) {
            const new_settings = {};
            for (let a = 0; a < data.match_settings.length; a++) {
               if (data.match_settings[a].type === 'player') {
                  new_settings[data.match_settings[a].title] = data.match_settings[a].value.display_name;
               } else {
                  new_settings[data.match_settings[a].title] = data.match_settings[a].value;
               }
            }
            console.log('new_settings: ', new_settings);
            const push_match = new Push_Match({
               t_id: data.t_id,
               tc_id: data.tc_id,
               load_mid: data.load_mid,
               group_name: data.group_name,
               match_type: data.match_type,
            });

            const match_code = data.match_code;
            const match_players = data.players;

            Handle_Game_Change(new_settings, 'new', match_code, push_match, match_players).then(async (result) => {
               console.log('Handle_Game_Change - result: ', result);
               
               const dbHandler = DBHandler();
               build_sme_con().then(async (sme_con) => {
                  const api_control = API_CONTROL({ sme_con: sme_con });
                  const sync = await dbHandler.load_sync();
                  const device_id = parseInt(sync.device_id);
                  api_control.SME.mutation.update_push_match(push_match.load_mid, {
                     mid: null,
                     device_id: device_id + "",
                     loaded_status: 3,
                  }).then((result) => {
                     console.warn('Push result: ', result);
                  });
               });
            });
         }
      });
   }


   return (
      <div className={style.wrap} ref={wrapper}>
         <div className={style.title} > Select Match to Load </div>
         <LoadingWheel show={loadingShow} />
         {noMatch ? (<div className={style.message}> No Matches available </div>) : null}
         {matchData.map((match, i) => <PushMatchBox key={"PushMatchBox-" + i} match={match} onClick={(d) => { select_prompt(d) }} />)}
      </div>
   );
}

export default PushMatchLoader;
