import React, { useEffect, useState } from 'react';
import Overlay from '../../overlay-wrap/overlay-wrap';
import ScoreToolBlock from '../score-block/toolblock/toolblock';
import AdImage from '../../ad-image/ad-image';
import style from './warm-up-block-style.module.scss';
import DBHandler from '../../../javascript/index-db/db-handler.js';
import Clock from '../../clock/clock.js';
import { SPORT_BASE, NAV_CONTROL, GLOBAL_CSS } from '../../../javascript/services/globalVars.js';
import { genKeyRan } from '../../../javascript/utils/utils.js';

function WarmUpBlock(Props) {
   let visibility = 0;

   if (typeof Props.visibility !== 'undefined') {
      visibility = Props.visibility;
   }

   const [btnList, setBtnList] = useState([]);
   const [sportSettings, setSportSettings] = useState([]);

   const load_match = async () => {
      if (visibility === 1) {
         const dbHandler = DBHandler();
         const app_state = await dbHandler.load_app_state();
         if (app_state.game_state === 'warmup') {
            await SPORT_BASE.loadMatch(app_state.match_id);
            const settings = SPORT_BASE.sport_object.get_settings();
            const filtered_settings = SPORT_BASE.filterSettings(settings);
            setSportSettings(filtered_settings);
            // setSportSettings(settings);
            setBtnList(SPORT_BASE.sport_object.warmup.tool_block);
         }
      }
   }

   const editMatchBtn = () => {
      NAV_CONTROL.openMainMenu();
      NAV_CONTROL.openMainMenuEdit();
   }

   useEffect(() => {
      load_match();
   }, [visibility]);

   return (
      <Overlay visibility={visibility} >
         <div className={style.wrap} style={GLOBAL_CSS.background}>
            <div className={style.head}> Warm-up </div>
            <div className={style.body}>
               <div className={style.matchSettings}>
                  <h3>Match Settings</h3>
                  {sportSettings.map((item) => {
                     let value = item.value;
                     if (item.type === 'radio') {
                        const index = item.options.findIndex(x => x.value === item.value);
                        if (index !== -1) {
                           value = item.options[index].label;
                        }
                     }
                     if (item.value !== '') {
                        return (<p key={genKeyRan(item.label)} > <b>{item.label}</b> {value} </p>)
                     }
                  })}
                  <button className={style.editBtn} onClick={editMatchBtn}> Is this Correct? Click here to change it NOW! </button> 
               </div>
               <div className={style.centerBlock}>
                  <div className={style.centerBlock_Space}>
                     <Clock settings={Props.warmupTimer} ></Clock>
                  </div>
                  <ScoreToolBlock extraClass={style.btnSpace} btnList={btnList} ></ScoreToolBlock>
                  <div className={style.centerBlock_Space}></div>
               </div>
               <div className={style.addBlock}>
                  <AdImage></AdImage>
               </div>
            </div>
         </div>
      </Overlay>
   );
}

export default WarmUpBlock;