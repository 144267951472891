import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import PageWrap from '../page-wraper/page-wrap';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import loadingImage from '../../images/sport/generic/Logo.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GLOBAL_CSS, load_DB_vars } from '../../javascript/services/globalVars';
import styles from './landing-style.module.scss';

function LandingPage(Props) {
   const navigate = useNavigate();

   const initApp = () => {
      return new Promise(async (resolve) => {
         await load_DB_vars();
         resolve(true);
      });
   }

   useEffect(() => {
      initApp().then(() => {
         setTimeout(function () {
            navigate("/score");

         }, 3_000);
      });
   });

   return (
      <PageWrap>
         <div className={styles.mainWrap} style={GLOBAL_CSS.background}>
            <div className={styles.imgWrap}>
               <img src={loadingImage} alt="logo"></img>
            </div>
            <div>
               LOADING <FontAwesomeIcon icon={faSpinner} spin />
            </div>
         </div>
      </PageWrap>
   );
}


export default LandingPage;