import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import style from './overlay-slide-menu-style.module.scss';

function OverlaySlideMenu(Props) {
   const closeBtn = () => { Props.hide(); }
   const onClick = (fun) => {
      fun();
      closeBtn();
   }

   return (
      <div className={`${style.wrap}`}>
         <div className={`${style.slideMenu}`}>
            <div className={`${style.head}`}>
               <div className={`${style.closeBtn}`} onClick={closeBtn}> <FontAwesomeIcon icon={faCircleXmark} /> </div>
            </div>
            <div className={`${style.body}`}>
               {Props.options.map(i => { return (<div className={style.actionLink} onClick={() => { onClick(i.fun); }} >{i.jsx.build_jsx()}</div>) })}
            </div>
         </div>
      </div>
   );
}

export default OverlaySlideMenu;