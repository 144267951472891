import { gql } from '@apollo/client';
import { createClient } from 'graphql-ws';
import { Sleep } from '../utils/utils';
import { GLOBAL_VARS } from './globalVars';
import { setContext } from '@apollo/client/link/context';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { ApolloClient, InMemoryCache, split, HttpLink } from '@apollo/client';
import auth from './auth';

const SERVER_WS_SYNC_URL = process.env.REACT_APP_WS_SYNC_URL;
const SERVER_HTTP_SYNC_URL = process.env.REACT_APP_HTTP_SYNC_URL;





const API_CONTROL = (args = {}) => {
   var sme_con = typeof args.sme_con !== 'undefined' ? args.sme_con : null;

   const sme_query_user_account = (user_id) => {
      return new Promise(async (resolve, reject) => {
         try {
            const query = gql`
            query Account($userId: ID!) {
               account(user_id: $userId) {
                  user_id
                  user_fname
                  user_lname
                  user_email
                  user_img
               }
            }`;

            const data = { userId: user_id };

            graphql_call_query(query, data).then((result) => {
               // console.warn('DEBUG sme_query_user_account result: ', result.result.account_info)
               resolve(result.result.account);
            }).catch(() => {
               resolve(false);
            });
         } catch (error) {
            console.error('ERROR -- sme_query_user_account - root', error);
            reject(error);
         }
      });
   }


   const sme_query_account_details = () => {
      return new Promise(async (resolve, reject) => {
         try {
            const query = gql`
               query Account_info($deviceId: String!, $token: String!) {
               account_info(device_id: $deviceId, token: $token) {
                  user_id
                  test
                  name
                  username
                  user_img
               }
            }`;

            const token = await sme_con.auth.get_token();
            const data = {
               deviceId: token.deviceID,
               token: token.refreshToken
            };
            graphql_call_query(query, data).then((result) => {
               // console.warn('DEBUG sme_query_account_details result: ', result.result.account_info)
               resolve(result.result.account_info);
            }).catch(() => {
               resolve(false);
            });
         } catch (error) {
            console.error('error', 'ERROR -- sme_query_account_details - root', error);
            reject(error);
         }
      });
   }

   const sme_query_club_courts = (club_id = null, sport_type = null) => {
      return new Promise(async (resolve, reject) => {
         try {
            const query = gql`
            query Club_courts($clubId: ID!, $sportType: String) {
            club_courts(club_id: $clubId, sport_type: $sportType) {
               ccfg_id
               club_id
               court_name
               court_image
               court_number
               court_status
               court_show_number
               sport_type
               device_id
            }
         }`;

            const data = { clubId: club_id, sportType: sport_type };
            graphql_call_query(query, data).then((result) => {
               // console.warn('DEBUG - sme_query_club_courts - result: ', result.result)
               resolve(result.result.club_courts);
            }).catch(() => {
               resolve(false);
            });
         } catch (error) {
            console.error('error', 'ERROR -- sme_query_user_club - root', error);
            reject(error);
         }
      });
   }

   const sme_query_club_ads = (club_id = null, sport_type = null) => {
      return new Promise(async (resolve, reject) => {
         try {
            const query = gql`
            query Club_ads($clubId: ID!, $sportType: String) {
               club_ads(club_id: $clubId, sport_type: $sportType) {
                  ad1 {
                     path
                  }
                  ad2 {
                     path
                  }
                  ad3 {
                     path
                  }
                  ad4 {
                     path
                  }
                  ad5 {
                     path
                  }
                  club_img {
                     path
                  }
               }
            }`;

            const data = { clubId: club_id, sportType: sport_type };
            graphql_call_query(query, data).then((result) => {
               // console.warn('DEBUG - sme_query_club_ads - result: ', result.result)
               resolve(result.result.club_ads);
            }).catch(() => {
               resolve(false);
            });
         } catch (error) {
            console.error('error', 'ERROR -- sme_query_club_ads - root', error);
            reject(error);
         }
      });
   }

   const sme_query_club_push_match = (club_id = null, sport_type = null, device_id = null) => {
      return new Promise(async (resolve, reject) => {
         try {
            const query = gql`
            query Push_match($clubId: ID!, $sportType: String!, $deviceId: ID!) {
               push_match(club_id: $clubId, sport_type: $sportType, device_id: $deviceId) {
                  match_count
                  matches {
                     loaded_mid
                     club_id
                     sport_type
                     match_load_type
                     device_id
                     ccfg_id
                     t_id
                     player_a_id
                     player_b_id
                     player_c_id
                     player_d_id
                     match_code
                     match_type
                     game_points
                     best_of
                     win_by
                     match_duration
                     icon_status
                     cheeky_status
                     loaded_status
                     mid
                     status
                     timestamp
                     match_order
                     continuous_timer
                     player_a_checked
                     player_b_checked
                     player_c_checked
                     player_d_checked
                     match_defaulted
                     on_hold
                     match_time
                     sport_option_1
                     sport_option_2
                     sport_option_3
                     sport_option_4
                     sport_option_5
                     sport_option_6
                     sport_option_7
                     sport_option_8
                     sport_option_9
                     sport_option_10
                     lgid
                     auto_est_time_m
                     auto_match_pos
                     tc_id
                     div_id
                     schedule_hidden
                     player_override
                     override_name_1
                     override_name_2
                     override_name_3
                     override_name_4
                     override_code_1
                     override_code_2
                     override_code_3
                     override_code_4
                     hide_time
                     tc_name
                  }
               }
            }`;

            const data = { clubId: club_id, sportType: sport_type, deviceId: device_id };
            graphql_call_query(query, data).then((result) => {
               // console.warn('DEBUG - sme_query_club_push_match - result: ', result.result)
               resolve(result.result.push_match);
            }).catch(() => {
               resolve(false);
            });
         } catch (error) {
            console.error('error', 'ERROR -- sme_query_club_push_match - root', error);
            reject(error);
         }
      });
   }

   const sme_query_club_push_match_count = (club_id = null, sport_type = null, device_id = null) => {
      return new Promise(async (resolve, reject) => {
         try {
            const query = gql`
            query Push_match($clubId: ID!, $sportType: String!, $deviceId: ID!) {
               push_match(club_id: $clubId, sport_type: $sportType, device_id: $deviceId) {
                  match_count
               }
            }`;

            const data = { clubId: club_id, sportType: sport_type, deviceId: device_id };
            graphql_call_query(query, data).then((result) => {
               // console.warn('DEBUG - sme_query_club_push_match_count - result: ', result.result)
               resolve(result.result.push_match);
            }).catch(() => {
               resolve(false);
            });
         } catch (error) {
            console.error('error', 'ERROR -- sme_query_club_push_match_count - root', error);
            reject(error);
         }
      });
   }

   const sme_query_club_devices = (user_id = null) => {
      return new Promise(async (resolve, reject) => {
         try {
            const query = gql`
            query Sport_club($userId: ID!) {
               devices(user_id: $userId) {
                  device_id
                  name
                  type
                  identifier
                  tc_alert
                  tc_alert_date
                  tc_acknowledge
                  sport_type
               }
            }`;

            const data = { userId: user_id };
            graphql_call_query(query, data).then((result) => {
               // console.warn('DEBUG sme_query_club_devices - result: ', result.result)
               resolve(result.result.devices);
            }).catch(() => {
               resolve(false);
            });
         } catch (error) {
            console.error('error', 'ERROR -- sme_query_club_devices - root', error);
            reject(error);
         }
      });
   }

   const sme_query_user_club = (user_id = null) => {
      return new Promise(async (resolve, reject) => {
         try {
            const query = gql`
            query User_clubs($userId: ID!) {
               user_clubs(user_id: $userId) {
                  club_id
                  club_name
                  club_email
                  club_city
                  club_country
                  club_logo
                  default_sport
                  # club_phone
                  # total_courts
                  # club_www
                  # youtube_id
                  # facebook_id
               }
            }`;
            const data = { userId: user_id };
            graphql_call_query(query, data).then((result) => {
               // console.warn('DEBUG - sme_query_user_club - result: ', result.result)
               resolve(result.result.user_clubs);
            }).catch(() => {
               resolve(false);
            });
         } catch (error) {
            console.error('error', 'ERROR -- sme_query_user_club - root', error);
            reject(error);
         }
      });
   }

   const sme_query_user_details = (user_id) => {
      return new Promise(async (resolve, reject) => {
         try {
            const query = gql`
            query Account_info($userId: ID!) {
               account(user_id: $userId) {
                  user_id
                  user_fname
                  user_lname
                  user_email
                  user_img
               }
            }`;

            const data = { userId: user_id };
            graphql_call_query(query, data).then((result) => {
               // console.log('result: ', result.result.account)
               resolve(result.result.account);
            }).catch(() => {
               resolve(false);
            });
         } catch (error) {
            console.error('error', 'ERROR -- graphql_collect_users - root', error);
            reject(error);
         }
      });
   }

   const sme_query_match = (match_code) => {
      return new Promise(async (resolve, reject) => {
         try {
            const query = gql`
            query Query($matchCode: String!) {
               match(match_code: $matchCode) {
                  mid
                  user_id
                  match_code
                  player1
                  player2
                  player3
                  player4
                  match_type
                  completed
                  player_country
                  game_points
                  best_of
                  win_by
                  p1game
                  p2game
                  date
                  timelimit
                  p1code
                  p2code
                  p3code
                  p4code
                  deviceid
                  groupid
                  p1_Type
                  p2_Type
                  p3_Type
                  p4_Type
                  sport_type
                  match_graded
                  win_by_pmt
                  win_by_pmt_dec
                  match_option
                  deciding_match
                  point_b_points
                  tiebreak_points
                  dec_m_winby
                  score_opt
                  match_max_points
                  match_select_sport
                  uuid
                  match_defaulted_player
                  source
                  ccfg_id
                  p1_color
                  p2_color
                  p3_color
                  p4_color
               }
            }`;

            const data = { matchCode: match_code };
            graphql_call_query(query, data).then((result) => {
               // console.log('result: ', result.result.account)
               resolve(result.result.match);
            }).catch(() => {
               resolve(false);
            });
         } catch (error) {
            console.error('error', 'ERROR -- sme_query_match - root', error);
            reject(error);
         }
      });
   }


   const sme_query_user_group = (group_id) => {
      return new Promise(async (resolve, reject) => {
         try {
            const query = gql`
            query User_group($tId: ID!) {
               user_group(t_id: $tId) {
                  t_id
                  t_name
                  start_date
                  reg_close_date
                  details
                  user_id
                  tstatus
                  signup_key
                  active
                  icon_option
                  club_id
                  sport_type
                  match_type
                  game_points
                  best_of
                  win_by
                  cheeky_status
                  estimated_match_duration
                  timestamp
                  continuous_timer
                  sport_option_1
                  sport_option_2
                  sport_option_3
                  sport_option_4
                  sport_option_5
                  sport_option_6
                  sport_option_7
                  sport_option_8
                  sport_option_9
                  sport_option_10
                  tc_id
                  div_id
                  tc_sts
               }
            }`;

            const data = { tId: group_id };
            graphql_call_query(query, data).then((result) => {
               // console.log('DEBUG - sme_query_user_group -result: ', result.result)
               resolve(result.result.user_group);
            }).catch(() => {
               resolve(false);
            });
         } catch (error) {
            console.error('error', 'ERROR -- sme_query_user_group - root', error);
            reject(error);
         }
      });
   }

   const sme_query_user_payments = (user_id, package_id = 0) => {
      return new Promise(async (resolve, reject) => {
         try {
            const query = gql`
            query User_payment($userId: ID!, $packageid: ID!) {
               user_payment_by_package(user_id: $userId, packageid: $packageid) {
                  py_id
                  user_id
                  py_for_users
                  py_date
                  py_exp
                  py_amount
                  py_sts
                  payment_sts
                  packageid
               }
            }`;

            const data = { userId: user_id, packageid: package_id };
            graphql_call_query(query, data).then((result) => {
               // console.log('DEBUG - sme_query_user_payments -result: ', result.result)
               resolve(result.result.user_payment_by_package);
            }).catch(() => {
               resolve(false);
            });
         } catch (error) {
            console.error('error', 'ERROR -- sme_query_user_payments - root', error);
            reject(error);
         }
      });
   }

   const sme_query_sync_usage_check = (user_id, device_id) => {
      return new Promise(async (resolve, reject) => {
         try {
            const query = gql`
            query Sync_usage_check($userId: ID!, $deviceId: ID!) {
               sync_usage_check(user_id: $userId, device_id: $deviceId) {
                  Result
               }
            }`;

            const data = { userId: user_id, deviceId: device_id };
            graphql_call_query(query, data).then((result) => {
               // console.log('DEBUG - sme_query_sync_usage_check -result: ', result.result)
               resolve(result.result.sync_usage_check);
            }).catch(() => {
               resolve(false);
            });
         } catch (error) {
            console.error('error', 'ERROR -- sme_query_sync_usage_check - root', error);
            reject(error);
         }
      });
   }

   const sme_query_admin_control = (club_id, sport_type, tc_id = 0) => {
      return new Promise(async (resolve, reject) => {
         try {
            const query = gql`
            query Push_match($clubId: ID!, $sportType: String, $tcId: ID!) {
               admin_control(club_id: $clubId, sport_type: $sportType, tc_id: $tcId) {
                  auto_sort
                  match_started
                  hide_on_court
                  hide_est_time
                  hide_schedule
                  custom_banner
                  match_approval
                  auto_sort_next_on
                  strict_auto
                  hide_new_match_app
                  tc_name
               }
            }`;

            const data = { 
               clubId: club_id, 
               sportType: sport_type,
               tcId: tc_id,
            };
            graphql_call_query(query, data).then((result) => {
               // console.log('DEBUG - sme_query_admin_control -result: ', result.result)
               resolve(result.result.admin_control);
            }).catch(() => {
               resolve(false);
            });
         } catch (error) {
            console.error('error', 'ERROR -- sme_query_admin_control - root', error);
            reject(error);
         }
      });
   }



   const sme_query_match_full = (mid) => {
      return new Promise(async (resolve, reject) => {
         try {
            const query = gql`
            query Match_full($mid: ID!) {
               match_full(mid: $mid) {
                  match {
                     mid
                     user_id
                     match_code
                     player1
                     player2
                     player3
                     player4
                     match_type
                     completed
                     player_country
                     game_points
                     best_of
                     win_by
                     p1game
                     p2game
                     date
                     timelimit
                     p1code
                     p2code
                     p3code
                     p4code
                     deviceid
                     groupid
                     p1_Type
                     p2_Type
                     p3_Type
                     p4_Type
                     sport_type
                     match_graded
                     win_by_pmt
                     win_by_pmt_dec
                     match_option
                     deciding_match
                     point_b_points
                     tiebreak_points
                     dec_m_winby
                     score_opt
                     match_max_points
                     match_select_sport
                     uuid
                     match_defaulted_player
                     source
                     ccfg_id
                     p1_color
                     p2_color
                     p3_color
                     p4_color
                  }
                  match_relation {
                     mrid
                     mid
                     p1l
                     p1r
                     p2l
                     p2r
                     points
                     winby
                     start_time
                     end_time
                     breaktime
                     break_reason
                     game_status
                     serve_pos
                     match_log {
                     ml_id
                     mrid
                     ml_content
                     ml_action
                     ml_logsts
                     ml_logtime
                     timestamp
                     breakt
                     server
                     serv_side
                     }
                  }
               }
            }`;

            const data = { mid: mid };
            graphql_call_query(query, data).then((result) => {
               // console.log('result: ', result.result.account)
               resolve(result.result.match_full);
            }).catch(() => {
               resolve(false);
            });
         } catch (error) {
            console.error('error', 'ERROR -- sme_query_match_full - root', error);
            reject(error);
         }
      });
   }

   const sme_query_connection_check = () => {
      return new Promise(async (resolve, reject) => {
         try {
            const query = gql`
            query Connection_check {
               connection_check {
                  status
               }
            }`;

            // const data = { userId: user_id };
            graphql_call_query(query).then((result) => {
               // console.warn('DEBUG sme_query_connection_check - result: ', result.result)
               resolve(result.result.connection_check);
            }).catch(() => {
               resolve(false);
            });
         } catch (error) {
            console.error('error', 'ERROR -- sme_query_connection_check - root', error);
            reject(error);
         }
      });
   }



   // TODO come back to this.
   const sme_subscription_keep_alive = async (device_id, callback = null) => {
      try {
         const KEEP_ALIVE_QUERY = gql`
         subscription Subscription($deviceId: ID!) {
            keep_alive(device_id: $deviceId) {
               last_update
            }
         }`;

         const data = {
            deviceId: device_id
         }

         let loop_count = 0;

         sme_con.client.subscribe({
            query: KEEP_ALIVE_QUERY,
            variables: data,
         }).subscribe({
            next(data) {
               loop_count++;
               console.debug('%c [KEEP_ALIVE] >>> ' + loop_count, 'background: #222; color: lightblue', data.data.keep_alive.last_update);
               if (typeof callback === 'function') {
                  callback({
                     data: data.data.keep_alive,
                     sts: true
                  });
               }
            },
            error(err) {
               console.error('Error - keep_alive - Subscription: ', err);
               if (typeof callback === 'function') {
                  callback({
                     data: null,
                     sts: false
                  });
               }
            },
         });
      } catch (err) {
         console.error('Error - keep_alive: ', err);
         if (typeof callback === 'function') {
            callback({
               data: null,
               sts: false
            });
         }
      }
   }

   /*
   // ------------------------
   */

   const sme_mutation_create_device = (args) => {
      return new Promise(async (resolve) => {
         try {
            const query = gql`
            mutation Mutation($input: deviceInput) {
               createDevice(input: $input) {
                 device_id
                 type
                 sport_type
                 name
                 identifier
                 tc_acknowledge
                 tc_alert
               }
            }`;
            const data = {
               input: {
                  name: args.name,
                  sport_type: args.sport_type,
                  type: args.type,
                  user_id: parseInt(args.user_id),
                  identifier: args.identifier
               }
            }

            console.log('data: ', data);

            graphql_call_mutation(query, data).then((result) => {
               console.log('result: ', result)
               resolve(result.result.createDevice);
            }).catch(() => {
               resolve(false);
            });
         } catch (error) {
            console.error('error', 'ERROR -- sme_mutation_create_device - root', error);
            resolve(error);
         }
      });
   }

   const sme_mutation_update_device = (device_id, args) => {
      return new Promise(async (resolve) => {
         try {
            const query = gql`
            mutation Mutation($deviceId: ID!, $input: deviceInput) {
               updateDevice(device_id: $deviceId, input: $input) {
                  device_id
                  name
                  type
                  identifier
                  tc_alert
                  tc_alert_date
                  tc_acknowledge
                  sport_type
                  current_match
                  access_date
               }
            }`;

            const data = { input: {}, deviceId: 0 }
            data.deviceId = device_id;
            for (const key in args) {
               if (Object.hasOwnProperty.call(args, key)) {
                  data.input[key] = args[key];
               }
            }

            graphql_call_mutation(query, data).then((result) => {
               // console.warn('DEBUG - sme_mutation_update_device - result: ', result);
               resolve(result.result.updateDevice);
            }).catch(() => {
               resolve(false);
            });
         } catch (error) {
            console.error('error', 'ERROR -- sme_mutation_create_device - root', error);
            resolve(error);
         }
      });
   }

   const sme_mutation_push_match = (uuid = null, args) => {
      return new Promise(async (resolve) => {
         try {
            const query = gql`
            mutation Match($uuid: String!, $input: matchInput) {
               match(uuid: $uuid, input: $input) {
                  mid
                  user_id
                  match_code
                  player1
                  player2
                  player3
                  player4
                  match_type
                  completed
                  player_country
                  game_points
                  best_of
                  win_by
                  p1game
                  p2game
                  date
                  timelimit
                  p1code
                  p2code
                  p3code
                  p4code
                  deviceid
                  groupid
                  p1_Type
                  p2_Type
                  p3_Type
                  p4_Type
                  sport_type
                  match_graded
                  win_by_pmt
                  win_by_pmt_dec
                  match_option
                  deciding_match
                  point_b_points
                  tiebreak_points
                  dec_m_winby
                  score_opt
                  match_max_points
                  match_select_sport
                  uuid
                  match_defaulted_player
                  source
                  ccfg_id
                  p1_color
                  p2_color
                  p3_color
                  p4_color
               }
            }`;

            const data = { uuid: uuid, input: {} }
            for (const key in args) {
               if (Object.hasOwnProperty.call(args, key)) {
                  data.input[key] = args[key];
               }
            }

            // console.log('DEBUG data: ', data);
            graphql_call_mutation(query, data).then((result) => {
               // console.warn('DEBUG - sme_mutation_push_match - result: ', result);
               resolve(result.result.match);
            }).catch(() => {
               resolve(false);
            });
         } catch (error) {
            console.error('error', 'ERROR -- sme_mutation_push_match - root', error);
            resolve(error);
         }
      });
   }


   const sme_mutation_push_match_relation = (mrid = null, args) => {
      return new Promise(async (resolve) => {
         try {
            const query = gql`
            mutation Match_relation($mrid: ID!, $input: match_relationInput) {
               match_relation(mrid: $mrid, input: $input) {
                  mrid
                  mid
                  p1l
                  p1r
                  p2l
                  p2r
                  points
                  winby
                  start_time
                  end_time
                  breaktime
                  break_reason
                  game_status
                  serve_pos
               }
            }`;

            const data = { mrid: mrid, input: {} }
            for (const key in args) {
               if (Object.hasOwnProperty.call(args, key)) {
                  data.input[key] = args[key];
               }
            }

            // console.log('DEBUG - sme_mutation_push_match_relation - data: ', data);
            graphql_call_mutation(query, data).then((result) => {
               console.warn('DEBUG - sme_mutation_push_match_relation - result: ', result);
               resolve(result.result.match_relation);
            }).catch(() => {
               resolve(false);
            });
         } catch (error) {
            console.error('error', 'ERROR -- sme_mutation_push_match_relation - root', error);
            resolve(error);
         }
      });
   }

   const sme_mutation_push_match_log = (ml_id = null, args) => {
      return new Promise(async (resolve) => {
         try {
            const query = gql`
            mutation Mutation($mlId: ID!, $input: sq_match_logInput) {
               match_log(ml_id: $mlId, input: $input) {
                  ml_id
                  mrid
                  ml_content
                  ml_action
                  ml_logsts
                  ml_logtime
                  timestamp
                  breakt
                  server
                  serv_side
               }
            }`;

            const data = { mlId: ml_id, input: {} }
            for (const key in args) {
               if (Object.hasOwnProperty.call(args, key)) {
                  data.input[key] = args[key];
               }
            }

            console.log('DEBUG - sme_mutation_push_match_log - data: ', data);
            graphql_call_mutation(query, data).then((result) => {
               console.warn('DEBUG - sme_mutation_push_match_log - result: ', result);
               resolve(result.result.match_log);
            }).catch(() => {
               resolve(false);
            });
         } catch (error) {
            console.error('error', 'ERROR -- sme_mutation_push_match_log - root', error);
            resolve(error);
         }
      });
   }
   const sme_mutation_sync_match_logs = (mrid = null, args) => {
      return new Promise(async (resolve) => {
         try {
            const query = gql`
            mutation Mutation($input: [sq_match_logInput], $mrid: ID!) {
               match_logs(input: $input, mrid: $mrid) {
                  ml_id
                  mrid
                  ml_content
                  ml_action
                  ml_logsts
                  ml_logtime
                  timestamp
                  breakt
                  server
                  serv_side
               }
            }`;

            const data = { mrid: mrid, input: {} }
            data.input = args;

            // console.log('DEBUG - sme_mutation_sync_match_logs - data: ', data);
            graphql_call_mutation(query, data).then((result) => {
               // console.warn('DEBUG - sme_mutation_sync_match_logs - result: ', result);
               resolve(result.result.match_logs);
            }).catch(() => {
               resolve(false);
            });
         } catch (error) {
            console.error('error', 'ERROR -- sme_mutation_sync_match_logs - root', error);
            resolve(error);
         }
      });
   }

   const sme_mutation_sync_match_relation = (mid = null, args) => {
      return new Promise(async (resolve) => {
         try {
            const query = gql`
            mutation Mutation($mid: ID!, $input: [match_relationInput]) {
               match_relations(mid: $mid, input: $input) {
                  mrid
                  mid
                  p1l
                  p1r
                  p2l
                  p2r
                  points
                  winby
                  start_time
                  end_time
                  breaktime
                  break_reason
                  game_status
                  serve_pos
               }
            }`;

            const data = { mid: mid, input: {} }
            data.input = args;

            // console.log('DEBUG - sme_mutation_sync_match_relation - data: ', data);
            graphql_call_mutation(query, data).then((result) => {
               // console.warn('DEBUG - sme_mutation_sync_match_relation - result: ', result);
               resolve(result.result.match_relations);
            }).catch(() => {
               resolve(false);
            });
         } catch (error) {
            console.error('error', 'ERROR -- sme_mutation_sync_match_relation - root', error);
            resolve(error);
         }
      });
   }

   const sme_mutation_update_push_match = (loaded_mid = null, args) => {
      return new Promise(async (resolve) => {
         try {
            const query = gql`
            mutation Mutation($loadedMid: ID!, $input: push_match_input) {
               push_match(loaded_mid: $loadedMid, input: $input) {
                  Result
               }
            }`;

            const data = { loadedMid: loaded_mid, input: {} }
            data.input = args;

            // console.log('DEBUG - sme_mutation_update_push_match - data: ', data);
            graphql_call_mutation(query, data).then((result) => {
               // console.warn('DEBUG - sme_mutation_update_push_match - result: ', result);
               resolve(result.result.push_match);
            }).catch(() => {
               resolve(false);
            });
         } catch (error) {
            console.error('error', 'ERROR -- sme_mutation_update_push_match - root', error);
            resolve(error);
         }
      });
   }

   const sme_mutation_run_match_complete = (uuid = null) => {
      return new Promise(async (resolve) => {
         try {
            const query = gql`
            mutation Match_complete($uuid: String!) {
               match_complete(uuid: $uuid) {
                  Result
               }
            }`;

            const data = { uuid: uuid }
            graphql_call_mutation(query, data).then((result) => {
               // console.warn('DEBUG - sme_mutation_run_match_complete - result: ', result);
               resolve(result.result.match_complete);
            }).catch(() => {
               resolve(false);
            });
         } catch (error) {
            console.error('error', 'ERROR -- sme_mutation_run_match_complete - root', error);
            resolve(error);
         }
      });
   }

   /*
   // ------------------------
   */

   const graphql_call_query = (query, variables) => {
      return new Promise(async (resolve, reject) => {
         try {
            if (GLOBAL_VARS.server_connection) {
               sme_con.client.query({
                  query: query,
                  variables: variables,
               }).then((result) => {
                  // console.log('***>>>>> graphql_call_query result: ', result);
                  resolve({ result: result.data, details: result });
               }).catch((error) => {
   
                  console.error('error', 'ERROR -- graphql_call_query - catch:', error.graphQLErrors);
                  reject(error);
               });
            } else {
               console.log('graphql_call_query:  NOT CONNECTION');
               reject(false);
            }
         } catch (error) {
            console.error('error', 'ERROR -- graphql_call_query - catch:', error);
            reject(error);
         }
      });
   }

   const graphql_call_mutation = (mutation, variables) => {
      return new Promise((resolve, reject) => {
         try {
            if (GLOBAL_VARS.server_connection) {
               // console.log('>>>--- graphql_call_mutation ---<<<');
               // console.log('mutation: ', mutation)
               // console.log('variables: ', variables)
               sme_con.client.mutate({
                  mutation: mutation,
                  variables: variables,
               }).then((result) => {
                  // console.log('***>>>>> graphql_call_mutation result: ', result);
                  resolve({ result: result.data, details: result });
               }).catch((error) => {
                  console.error('error', 'ERROR -- graphql_call_mutation - catch:', error.graphQLErrors);
                  reject(error);
               });
            } else {
               console.log('graphql_call_mutation:  NOT CONNECTION');
               reject(false);
               // resolve({ result: result.data, details: result });
            }
         } catch (error) {
            console.error('error', 'ERROR -- graphql_call_mutation - catch:', error);
            reject(error);
         }
      });
   }


   const sme_query = {
      get_user: sme_query_user_account,
      account_details: sme_query_account_details,
      user_clubs: sme_query_user_club,
      club_courts: sme_query_club_courts,
      club_devices: sme_query_club_devices,
      club_ads: sme_query_club_ads,
      match: sme_query_match,
      match_full: sme_query_match_full,
      connection_check: sme_query_connection_check,


      push_match: sme_query_club_push_match,
      push_match_count: sme_query_club_push_match_count,

      user_group: sme_query_user_group,
      user_payments: sme_query_user_payments,
      sync_usage: sme_query_sync_usage_check,
      
      admin_control: sme_query_admin_control,

   }
   const sme_mutation = {
      create_device: sme_mutation_create_device,
      update_device: sme_mutation_update_device,
      // set_device_match: sme_mutation_set_device_match,

      push_match: sme_mutation_push_match,
      push_match_relation: sme_mutation_push_match_relation,
      sync_match_relation: sme_mutation_sync_match_relation,
      push_match_log: sme_mutation_push_match_log,
      sync_match_logs: sme_mutation_sync_match_logs,


      update_push_match: sme_mutation_update_push_match,
      run_match_complete: sme_mutation_run_match_complete,

   }
   const sme_subscription = {
      keep_alive: sme_subscription_keep_alive,
   }


   const sme = {
      query: sme_query,
      mutation: sme_mutation,
      subscription: sme_subscription,
   }

   return { SME: sme }
}


class SME_CON {
   auth = null;
   client = null;
   ready = false;

   constructor(authObj, args = {}) {
      make_authenticated_client().then((client) => {
         this.client = client;
         this.auth = authObj;
         this.ready = true;
      });
   }
}





const make_client = (auth) => {
   let client = null;

   const authLink = setContext(async (_, { headers }) => {
      // get the authentication token from local storage if it exists
      const token = await auth.get_token();
      // console.log('%c [BACKEND - SYNC] ' + '>>> Authentication Token <<<', 'background: #222; color: green');
      // console.log('------------------------Get Token  ', token);
      // return the headers to the context so httpLink can read them
      return {
         headers: {
            ...headers,
            authorization: token ? `Bearer ${token.token}` : "",
         }
      }
   });
   const httpLink = new HttpLink({
      uri: SERVER_HTTP_SYNC_URL,
      options: { reconnect: true, }
   });
   const wsLink = new GraphQLWsLink(createClient({
      url: SERVER_WS_SYNC_URL,
      options: { reconnect: true, }
   }));

   // The split function takes three parameters:
   //
   // * A function that's called for each operation to execute
   // * The Link to use for an operation if the function returns a "truthy" value
   // * The Link to use for an operation if the function returns a "falsy" value
   const splitLink = split(
      ({ query }) => {
         const definition = getMainDefinition(query);
         return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
         );
      },
      authLink.concat(wsLink),
      authLink.concat(httpLink),
   );

   client = new ApolloClient({
      link: splitLink,
      cache: new InMemoryCache(),
      defaultOptions: {
         watchQuery: {
            fetchPolicy: "no-cache",
            errorPolicy: "ignore",
         },
         query: {
            fetchPolicy: "no-cache",
            errorPolicy: "all",
         },
      },
   });

   return client;
}

const make_authenticated_client = () => {
   return new Promise((resolve) => {
      try {
         const authObj = auth();
         authObj.init().then((authResult) => {
            // console.warn('DEBUG - make_authenticated_client Result: ', authResult);
            resolve(make_client(authObj));
         });
      } catch (error) {
         resolve(false);
      }
   });
}



export {
   SME_CON,
   make_client,
   API_CONTROL,
   make_authenticated_client,
}