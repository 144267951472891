import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTablet } from '@fortawesome/free-solid-svg-icons'
import $ from 'jquery';
import Input from '../form/input/input';
import RemoteImage from '../remote_img/remote_img';
import style from './sport-selector-style.module.scss';

function SportSelector(Props) {

   const select = (value) => {
      if (typeof Props.select_callback === 'function') {
         Props.select_callback(value);
      }
   }

   return (
      <div className={style.wrap}>
         <h2>{Props.text}</h2>
         <div className={style.sport_container}>
            <div className={style.sport_list} >
               {typeof Props.sport_list !== 'undefined' ?
                  Props.sport_list.map(sport => {
                     return (<div key={sport.id} className={style.sport_wrap} onClick={() => {select(sport.id)}} >
                        <RemoteImage className={style.sport_img} src={sport.image}/>
                        {sport.name}
                     </div>)
                  }) : null
               }
            </div>
           

         </div>
      </div>
   );
} 

export default SportSelector;