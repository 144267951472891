import React, { useEffect } from 'react';
import $ from 'jquery';

function CastButton(Props) {
   useEffect(() => {
      if (typeof window.cast !== 'undefined' && typeof window.chrome.cast !== 'undefined') {
         $('google-cast-launcher').show();
      }
   });
   return (<google-cast-launcher> {Props.children} </google-cast-launcher>);
}

export default CastButton;