import React from 'react';
import { useEffect, useState } from "react"
import ToolBlockHorizontal from '../tool-block-horizontal/tool-block-horizontal';
import { SPORT_BASE } from '../../javascript/services/globalVars';
import $ from 'jquery';
import style from './score-toolbar-style.module.scss';

// import ToolbarBtn from './toolbar-btn/toolbar-btn';                // Deprecate these
// import ToolbarButtons from '../../javascript/sport/toolbarBtns';   // Deprecate these

function ScoreToolbar(Props) {
   const [pinState, setPinState] = useState(false);
   const [toolBlock, setToolBlock] = useState([]);
   const [pinTextState, setPinTextState] = useState('Pin');
   const pinBtnClass = style.toolbarPinBtnWrap;

   let styleVisible = '';
   if (Props.state === 1) {
      styleVisible = style.isVisible;
   }

   const pinBtn = () => {
      if (pinState) {
         setPinState(false);
         setPinTextState('Pin');
      } else {
         setPinState(true);
         setPinTextState('Unpin');
      }
   }

   const offElementClick = () => {
      $(document).on('click', (e) => {
         // >>> Closes Menu on button click
         if ($(e.target).closest('.Btn_toolSolid').length === 1) {
            if (typeof Props.callback === 'function') {
               const pingText = $('.' + pinBtnClass).text();
               if (pingText === 'Pin') {
                  Props.callback();
               }
            }
         }
         
         if (!$(e.target).closest(`.${style.toolbarWrap}, .${style.toolbarBtnWrap}, .SideMenuBtn`).length) {
            if (typeof Props.callback === 'function') {
               const pingText = $('.' + pinBtnClass).text();
               if (pingText === 'Pin') {
                  Props.callback();
               }
            }
         }
      });
   }

   useEffect(() => {
      setToolBlock(SPORT_BASE.sport_object.tool_blocks.game_menu); 
   }, [])

   useEffect(() => {
      offElementClick();
      return () => {
         $('document').off('click');
      };
   }, []);

   return (
      <div className={`${style.toolbarWrap} ${styleVisible}`}>
         <div className={style.toolbarBtnWrap}>
            <ToolBlockHorizontal tool_block={toolBlock} />
         </div>
         <div className={pinBtnClass} onClick={pinBtn} >
            <span>
               {pinTextState}
            </span>
         </div>
      </div>
   );
}

export default ScoreToolbar;