import React, { useMemo, useEffect } from 'react';
import { useStopwatch } from 'react-timer-hook';
import style from './clock-style.module.scss';

function StopWatch({ offSet, control, callback }) {
   const {
      totalSeconds,
      seconds,
      minutes,
      hours,
      // days,
      // isRunning,
      start,
      pause,
      reset,
   } = useStopwatch({ autoStart: false, offsetTimestamp: offSet });

   const updateStopWatch = (control) => {
      if (typeof control !== 'undefined') {
         // console.log('update StopWatch: ', control);
         // console.log('offSet: ', offSet);
         if (control === 1) {
            // console.error('START Clock');
            reset(offSet);
            start();
         } else {
            // console.error('STOP Clock');
            reset();
            pause();
         }
      }
   }

   useMemo(() => {
      return updateStopWatch(control)
   }, [control])

   useEffect(() => {
      if (typeof callback === 'function' && totalSeconds > 0) {
         callback(totalSeconds);
      }
   }, [totalSeconds]);

   return (
      <div className={style.wrap}>
         <span>{hours === 0? '':(<span>{('0'+hours).slice(-2)}:</span>)} {('0'+minutes).slice(-2)}</span>:<span>{('0'+seconds).slice(-2)}</span>
      </div>
   );
}

export default StopWatch; 