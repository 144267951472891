import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from '@fortawesome/free-solid-svg-icons'
import style from './toolblock.module.scss';

function ScoreToolBlock(Props) {
   let items = [
      {
         pos: 5,
         hidden: false,
         jsx: (<div> Play <FontAwesomeIcon icon={faPlay} /> </div>),
         fun: null
      }
   ];

   if (typeof Props.btnList !== 'undefined') { items = Props.btnList; }

   const onClickFun = (fun) => {
      if (typeof fun === 'function') {
         fun().then((result) => {
            if (typeof Props.onBtnPress === 'function') {
               Props.onBtnPress(result); 
            }
         })
      } else {
         if (typeof Props.onBtnPress === 'function') {
            Props.onBtnPress(); 
         }
      }
   }
   
   const elements = [];
   for (let a = 1; a < 10; a++) {
      const index = items.findIndex(i => i.pos === a);
      if (index !== -1) {
         elements.push((<div key={`tool-${a}`} className={`${style.toolWrap} ${Props.extraClass} ${items[index] === true ? '' : style.showTool}`} onClick={() => { onClickFun(items[index].fun) }}>{items[index].jsx}</div>));
      } else {
         elements.push((<div key={`tool-${a}`} className={`${style.toolWrap}`}></div>));
      }
   }

   return (<div className={style.Wrap}>{elements}</div>);
}


export default ScoreToolBlock;