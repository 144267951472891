import Dexie from 'dexie';

export const db = new Dexie('sme-scoring');
db.version(1).stores({
   app_state: '++id, app_state, game_state, match_id, sport_type',
   matches: '++id, sport, match_state, settings, synced',
   clocks: 'clock_id, clock',
   account: 'user_id, username, user_img, timestamp',
   club: 'club_id, club_name, club_logo,timestamp',
   sync: 'device_id, ccfg_id, court_name, court_image, ad_1, ad_2, ad_3',
   settings: '++setting_id, sport',
});



// db.version(2).stores({
//    app_state: '++id, app_state, game_state, match_id',
//    matches: '++id, sport, match_state, settings',
// }).upgrade (trans => {
//    console.log('*-----Trans: ', trans)
//    trans.db.delete();
//    // var YEAR = 365 * 24 * 60 * 60 * 1000;
//    // return trans.table("friends").toCollection().modify (friend => {
//    //     friend.birthdate = new Date(Date.now() - (friend.age * YEAR));
//    //     delete friend.age;
//    // });
// });