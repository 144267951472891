import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Swal from 'sweetalert2';
import './javascript/cast/cast-service';
import reportWebVitals from './reportWebVitals';
import withReactContent from 'sweetalert2-react-content';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import DBHandler from './javascript/index-db/db-handler';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);



const update_process = () => {
  const dbHandler = DBHandler();
  dbHandler.reset_non_critical_table().then(() => {
    console.log('reset_non_critical_table -- DONE');

  }).catch((err) => {
    console.log('reset_non_critical_table -- CATCH');
    // window.location.reload();
  }).finally(() => {
    console.log('reset_non_critical_table -- FINALLY');
    // window.location.reload();
  });
}


const sw_config = {
  onUpdate: (registration) => {
    console.log('On Update: ', registration);
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      position: 'top-end',
      icon: '',
      title: 'Update Available',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'On next launch',
    }).then((result) => {
      if (result.isConfirmed) {
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        const MySwal = withReactContent(Swal)
        MySwal.fire({
          title: 'Loading Update',
          html: '',
          timer: 2000,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading();
          },
          willClose: () => {
            // clearInterval(timerInterval)
          }
        }).then((result) => {
          if (result.dismiss === Swal.DismissReason.timer) {

            update_process();

            // const dbHandler = DBHandler();
            // dbHandler.reset_database().then(() => {
            // }).catch((err) => {
            //   window.location.reload();
            // }).finally(() => {
            //   window.location.reload();
            // });
          }
        });
      }
    });
  },
  onSuccess: (registration) => {
    console.log('On Success: ', registration);
    const MySwal = withReactContent(Swal)
    MySwal.fire({
      position: 'top-end',
      icon: '',
      title: 'Ready for offline use',
      showConfirmButton: false,
      timer: 1500
    });
  }
}


serviceWorkerRegistration.register(sw_config);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals();
// reportWebVitals(console.log);