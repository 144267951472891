import React, { useState, useEffect, useRef } from 'react';
import AdImage from '../ad-image/ad-image';
import Slider from '../form/slider/slider';
import { useIsVisible } from 'react-is-visible';
import DropDown from '../form/dropdown/dropdown';
import RemoteImage from '../remote_img/remote_img';
import CastButton from '../chrome-cast/cast-button';
import InputWrap from '../form/input-wrap/input-wrap';
import DBHandler from '../../javascript/index-db/db-handler';
import WebSpeech from '../../javascript/webSpeechAPI/webSpeech';
import { update_game_state_timestamp } from '../../javascript/sport/sport-function';
import { Sleep, Get_Device_Type, Timestamp_to_String } from '../../javascript/utils/utils';
import { CAST_Get_Settings, CAST_Update_Screen } from '../../javascript/cast/cast-control';
import { SETTINGS, SPORT_BASE, GLOBAL_VARS, Update_GLOBAL_CSS } from '../../javascript/services/globalVars';
import style from './settings-style.module.scss';

const speechObj = WebSpeech();
const dbHandler = DBHandler();

function SettingsTab(Props) {
   const wrapper = useRef();
   const isVisible = useIsVisible(wrapper);
   const [paid, setPaid] = useState(() => 'Free');
   const [buttonList, setButtonList] = useState([]);
   const [voiceValue, setVoiceValue] = useState('');
   const [syncDate, setSyncDate] = useState(() => '');
   const [syncData, setSyncData] = useState(() => { });
   const [voiceOptions, setVoiceOptions] = useState([]);
   const [castRunning, setCastRunning] = useState(false);
   const [castVoiceValue, setCastVoiceValue] = useState('');
   const [accountData, setAccountData] = useState(() => { });
   const [castVoiceOptions, setCastVoiceOptions] = useState([]);
   const [castBorderSize, setCastBorderSize] = useState(() => 1);
   const [speechRateValue, setSpeechRateValue] = useState(() => 1);
   const [backgroundValue, setBackgroundValue] = useState('Default');
   const [castSpeechRateValue, setCastSpeechRateValue] = useState(() => 1);

   const background_options = [
      { label: 'Default', value: 'Default' },
      { label: 'Light', value: 'Light' },
      { label: 'Dark', value: 'Dark' },
      { label: 'Blue', value: 'Blue' },
      { label: 'Green', value: 'Green' },
      { label: 'Pink', value: 'Pink' },
      { label: 'Purple', value: 'Purple' },
      { label: 'Linear', value: 'Linear' },
   ];

   const handleChange = (event) => {
      const elementName = event.target.name;
      const elementValue = event.target.value;
      if (elementName === 'Voice') {
         SETTINGS.voice = elementValue;
         speechObj.text_to_speech('Voice Changed');
         dbHandler.saveSettings({
            voice: elementValue,
         });
      } else if (elementName === 'SpeechRate') {
         SETTINGS.speech_rate = elementValue;
         dbHandler.saveSettings({
            speech_rate: elementValue,
         });
      } else if (elementName === 'Background') {
         SETTINGS.background = elementValue;
         dbHandler.saveSettings({
            background: elementValue,
         }).then(() => {
            const result = Update_GLOBAL_CSS('background', elementValue);
            if (result) {
               setTimeout(function () {
                  update_game_state_timestamp().then(() => {
                     loadSettings();
                  })
               }, 500);
            }
         });
      }
   }

   const handleSubmit = () => { console.log('Form Submit'); }

   const loadSettings = async () => {
      const settings = await dbHandler.loadSettings();
      setVoiceValue(settings.voice);
      setBackgroundValue(settings.background);
      setSpeechRateValue(parseFloat(settings.speech_rate));
   }

   const handleChangeCast = (event) => {
      const elementName = event.target.name;
      const elementValue = event.target.value;

      if (elementName === 'BorderSize') {
         dbHandler.saveSettings({
            cast_border_size: elementValue,
         }).then(() => {
            CAST_Update_Screen();
         });
      } else if (elementName === 'Voice') {
         dbHandler.saveSettings({
            cast_voice: elementValue,
         }).then(() => {
            CAST_Update_Screen();
         });
      } else if (elementName === 'SpeechRate') {
         dbHandler.saveSettings({
            cast_speech_rate: elementValue,
         }).then(() => {
            CAST_Update_Screen();
         });
      }
   }

   const load_cast_settings = async () => {
      setCastRunning(GLOBAL_VARS.cast_running);
      if (GLOBAL_VARS.cast_running) {
         const result = await CAST_Get_Settings();
         let castVoiceOptions = [];
         if (typeof result.voice_list !== 'undefined') {
            const voiceList = result.voice_list;
            for (let a = 0; a < voiceList.length; a++) {
               castVoiceOptions.push({ label: voiceList[a], value: voiceList[a] });
            }
         }
         setCastVoiceOptions(castVoiceOptions);
         setCastVoiceValue(SETTINGS.cast_voice);
         setCastBorderSize(SETTINGS.cast_border_size);
         setCastSpeechRateValue(SETTINGS.cast_speech_rate);
      }
   }

   const cast_watch = async () => {
      const cast_running = JSON.parse(JSON.stringify(GLOBAL_VARS.cast_running));
      for (let a = 0; a < 100; a++) {
         if (cast_running !== GLOBAL_VARS.cast_running) {
            load_cast_settings();
            break;
         }
         await Sleep(200);
      }
   }

   const load_account_settings = async () => {
      const sync = await dbHandler.load_sync();
      const club = await dbHandler.load_club();
      let account = await dbHandler.load_account();

      if (typeof club !== 'undefined') {
         account.username = club.club_name;
         account.user_img = club.club_logo;
      }
      const date = Timestamp_to_String(sync.timestamp);

      if (GLOBAL_VARS.free_app) { setPaid('Free'); } 
      else { setPaid('Paid'); }
      setSyncDate(date);
      setSyncData(sync);
      setAccountData(account);
   }

   const load_btn = async () => {
      const tools = await SPORT_BASE.sport_object.get_tool_block('settings_menu');
      const toolBtns = SPORT_BASE.sport_object.build_tools_2(tools);
      setButtonList(toolBtns);
   }

   useEffect(() => {
      if (isVisible === true) {
         loadSettings();
         const voiceOptions = [];
         const voiceList = speechObj.populateVoiceList();
         for (let a = 0; a < voiceList.length; a++) {
            voiceOptions.push({ label: voiceList[a].name, value: voiceList[a].name });
         }
         setVoiceOptions(voiceOptions);
         load_btn();
         load_cast_settings();
         load_account_settings();
      }
   }, [isVisible]);

   return (
      <div className={style.wrap} ref={wrapper}>

         {/* General Settings */}
         <div className={style.contentWrap}>
            <form onChange={handleChange} onSubmit={handleSubmit} >
               <InputWrap key="Background" label="Background:"><DropDown options={background_options} defaultValue={backgroundValue} name={'Background'}></DropDown></InputWrap>
               <InputWrap key="Voice" label="Voice:"><DropDown name={'Voice'} defaultValue={voiceValue} options={voiceOptions}></DropDown></InputWrap>
               <InputWrap key="SpeechRate" label="SpeechRate:"><Slider min={0.1} max={5} step={0.1} defaultValue={speechRateValue} name={'SpeechRate'}></Slider></InputWrap>
            </form>
         </div>

         {/* Cast Settings */}
         <div className={`${style.contentWrap} ${style.contentCenter}`}>
            <div className={style.castBtnWrap} onClick={() => { cast_watch(); }}>
               <div className={style.cast_box}>
                  <CastButton />
               </div>
            </div>

            {castRunning ? (
               <div className={style.castSettingsWrap}>
                  <form onChange={handleChangeCast} >
                     <InputWrap key="BorderSize" label="Border Size:"><Slider min={1} max={25} step={1} defaultValue={castBorderSize} name={'BorderSize'}></Slider></InputWrap>
                     <InputWrap key="Voice" label="Voice:"><DropDown name={'Voice'} defaultValue={castVoiceValue} options={castVoiceOptions}></DropDown></InputWrap>
                     <InputWrap key="SpeechRate" label="SpeechRate:"><Slider min={0.1} max={5} step={0.1} defaultValue={castSpeechRateValue} name={'SpeechRate'}></Slider></InputWrap>
                  </form>
               </div>
            ) : null}
         </div>

         {/* Details */}
         <div className={style.contentWrap}>
            {typeof accountData !== 'undefined' ? (
               <div className={style.account_wrap}>
                  <RemoteImage className={style.account_img} src={accountData.user_img} />
                  <span className={style.account_name}> {accountData.username} </span>
               </div>
            ) : null}
            <div className={style.btn_wrap}>
               {buttonList.map(btn => (btn))}
               <br />
            </div>
            <div className={style.sync_wrap}>
               {typeof syncData !== 'undefined' && syncData.ccfg_id !== 0 ? (
                  <div className={style.sync_box}>
                     {syncData.court_image !== '' ? (<RemoteImage className={style.court_img} src={syncData.court_image.substring(3)} />) : null}
                     <div className={style.sync_block}><span>Synced Court:</span> {syncData.court_name !== '' ? syncData.court_name : 'Not Set'}</div>
                  </div>
               ) : (
                  <div className={style.sync_box}>
                     <div className={style.sync_block}> <span>Synced Court:  </span> Not Set  </div>
                  </div>
               )}
            </div>
            <div className={style.device_wrap}>
               <span><b>Device Name:</b> {typeof syncData !== 'undefined' ? syncData.device_name : ''} </span>
               <span><b>Device Type:</b> {Get_Device_Type()} </span>
               <span><b>Last Synced:</b> {syncDate} </span>
               <span><b>Paid Status:</b> {paid} </span>
            </div>
         </div>

         {/* Image */}
         <div className={style.contentWrap}>
            <div className={style.img_wrap}>
               <div className={style.img_box}>
                  <AdImage />
               </div>
            </div>
         </div>

      </div>
   )
}

export default SettingsTab;