'use strict'
import React, { useEffect, useState } from 'react';
import reactCSS from 'reactcss';
import { ChromePicker } from 'react-color';
import style from './player-icon-style.module.scss';


function PlayerIcon(Props) {
   var letter = 'A';
   const [color, setColor] = useState(() => {});
   const [displayColorPicker, setDisplayColorPicker] = useState(false);

   useEffect(() => {
      if (typeof Props.defaultColor !== 'undefined') {
         setColor(Props.defaultColor);
      }
   }, [Props]);
   
   letter = typeof Props.letter !== 'undefined' ? Props.letter : 'A';
   
   const handleChange = (color) => {
      setColor(color.hex);
   };
   const handleClick = () => {
      var colorPicker = !displayColorPicker;
      setDisplayColorPicker(colorPicker);
   };
   const handleClose = () => {
      setDisplayColorPicker(false);
      if (typeof Props.callback === 'function') {
         Props.callback(Props.callbackData, color);
      }
   };

   const styles = reactCSS({
      'default': {
         color: { color: color },
         swatch: { cursor: 'pointer' },
         popover: {
            position: 'absolute',
            zIndex: '2',
            display: 'flex',
            top: '0',
            left: '0',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100vw',
            height: '100vh',
            background: '#00000042',
         },
         cover: {
            position: 'fixed',
            left: '0px',
            top: '0px',
            bottom: '0px',
            right: '0px',
            background: '#00000042',
         },
      },
   });

   return (
      <div className={style.wrap}>
         <div className={style.Icon} onClick={handleClick}>
            <div style={styles.color}>
               {letter}
            </div>
         </div>
         {displayColorPicker ? <div style={styles.popover}>
            <div style={styles.cover} onClick={handleClose} />
            <ChromePicker disableAlpha={true} color={color} onChange={handleChange} />
         </div> : null}
      </div>
   )
}


export default PlayerIcon
