// import Clock from "../clock-class/clockClass";
import Auth from "./auth";
import DBHandler from "../index-db/db-handler";
import Sync from "./sync";
import { Sleep, genRanID } from "../utils/utils";
import { Wait_For_Auth } from "../utils/utils";
import { GLOBAL_VARS } from "./globalVars";
// const REFRESH_TIMER = 60;
// var clockClass = Clock();
// var clockObj = clockClass.Clock;

var SERVICE_VAR = {
  id: null
}

const ServiceLauncher = () => {
  const init = async (loading_callback) => {
    // console.log('>>> ServiceLauncher <<<');

    if (SERVICE_VAR.id !== null) {
      console.warn('Killing Duplicate ServiceLauncher');
      return
    }

    SERVICE_VAR.id = genRanID();
    const authObj = Auth();
    const dbHandler = DBHandler();
    dbHandler.init();
    await Wait_For_Auth(authObj, true);

    authObj.init().then(async (authResult) => {
      // console.log('>>> Main authResult: ', authResult);
      if (authResult) {
        console.debug('%c [BACKEND - AUTH] >>> LOGGED IN <<<', 'background: #222; color: lightblue');
        // Start Auth Sync...
        const syncObj = new Sync(authObj, {});
        if (typeof loading_callback === 'function') { loading_callback(true); }
        await syncObj.init();
        if (typeof loading_callback === 'function') { loading_callback(false); }
        do {
          try {
            await syncObj.sync_watch();
          } catch (error) {
            console.error('ERROR - ServiceLauncher - init - Sync Loop: ', error);
          }



          console.error('Sync Done Waiting....')
          // await Sleep(3000);
          await re_sync_wait(10_000);
          GLOBAL_VARS.matched_changed = Date.now();
          console.log('>>> Waiting Done <<<');


        } while (true);
      } else {
        console.error('Un expected Error - Auth Failed, Not logged int');
        // Start Offline Sync
      }
    }).catch((e) => {
      console.error('>>>>>>> Auth Check Crashed')
    });
  }
  return { init }
}



const re_sync_wait = (timeout = 5_000) => {
  return new Promise(async (resolve, reject) => {
    let timeout_id;

    timeout_id = setTimeout(() => {
      console.log('re_sync_wait timeout');
      resolve(true);
    }, timeout);

    let timestamp = Date.now();
    do {
      if (timestamp > GLOBAL_VARS.matched_changed) { await Sleep(50); }
    } while (timestamp > GLOBAL_VARS.matched_changed);

    clearTimeout(timeout_id);
    resolve(true);
  });
}




// |---------  -|-  ---------| \\

export default ServiceLauncher; 