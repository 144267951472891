import React from 'react';


import style from './page-wrap.module.scss';



function PageWrap(Props) {
  return (
    <div className={style.wrapper}>
         {Props.children}
   </div>
  );
}

export default PageWrap;

