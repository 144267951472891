import React, { useState, useMemo } from 'react';
import { SETTINGS } from '../../../../javascript/services/globalVars';
import style from './text-message-style.module.scss';

function TextMessage(Props) {
   const [text, setText] = useState(() => '');
   const [subText, setSubText] = useState(() => '');
   const blackList = [
      'Handout',
   ];

   useMemo(() => {
      // console.warn('Updated text message: ', SETTINGS.last_message);
      if (SETTINGS.last_message === '+--CLEAR--+') {
         setSubText('')
         setText('')
      } else {
         if (!blackList.includes(text)) {
            setSubText(JSON.parse(JSON.stringify(text)));
         }
         if (isNaN(parseInt(SETTINGS.last_message))) {
            setText(SETTINGS.last_message);
         } else {
            setText('');
         }
      }
   }, [SETTINGS.last_message])

   return (<div className={style.wrap} >
      <span>{text}</span>
      <span>{subText}</span>
   </div>);
}

export default TextMessage;