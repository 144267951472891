import React, { useState, useMemo } from 'react';
import style from './scoring-item-style.module.scss';

function ScoringItem({ pos, settings, callback }) {
   const [score, setScore] = useState(() => 0);

   var extraClass = '';
   if (pos === 'left') { extraClass = style.scoreItemLeft; } 
   else if (pos === 'right') { extraClass = style.scoreItemRight; }

   const updateScore = (settings) => {
      if (typeof settings !== 'undefined') {
         setScore(settings.score);
      }
   }

   useMemo(() => {
      return updateScore(settings)
   }, [settings])

   const scoreClick = () => {
      if (typeof callback === 'function') {
         callback({ pos });
      }
   }
   
   return (
      <div onClick={scoreClick} className={`${style.scoreItem} ${extraClass}`} > 
         <span> {score} </span>
      </div>
   );
}

export default ScoringItem;