import React, { useMemo, useState } from 'react';
import style from './clock-style.module.scss';
import StopWatch from './stop-watch';
import Timer from './timer';
import { build_sport_function } from '../../javascript/sport/sport-function';
import { SPORT_BASE } from '../../javascript/services/globalVars';

function Clock(Props) {
   const [sw_offSet, setSW_offSet] = useState(() => 0);
   const [sw_control, setSW_control] = useState(() => 0);
   const [tm_expiry, setTM_expiry] = useState(() => 0);
   const [tm_control, setTM_control] = useState(() => 0);
   const [clockType, setClockType] = useState(() => 'stopwatch');

   const tick_callback = (total, type) => {
      const clockSettings = Props.settings;
      if (typeof Props.settings !== 'undefined' && Props.settings !== false) {
         const clockLink = Props.settings.id;
         if (typeof clockLink !== 'undefined') {
            clockSettings.value = total;

            // if (total % 1 === 0) { console.log(clockLink+' - Tick', clockSettings, Props.settings); }

            if (typeof Props.settings.action !== 'undefined' && Props.settings.action !== null) {
               if (typeof Props.settings.action.length !== 'undefined') {
                  for (let a = 0; a < Props.settings.action.length; a++) {
                     if (Props.settings.action[a].value === total) {
                        const triggerFun = build_sport_function(Props.settings.action[a].fun);
                        if (typeof triggerFun === 'function') {
                           triggerFun();
                        }
                     }
                  }
               } else if (Props.settings.action.value === total) {
                  const triggerFun = build_sport_function({ type: Props.settings.action.fun });
                  if (typeof triggerFun === 'function') {
                     triggerFun();
                  }
               }
            }
            if ((type === 'SW' && sw_control === 1) || (type === 'TM' && tm_control === 1)) {
               SPORT_BASE.saveClock(clockSettings);
               // SPORT_BASE.saveSpecificState(clockLink, clockSettings);
            }
         }
      }
   }

   const updateClock = (settings) => {
      // console.warn('updateClock--->>> ', settings);
      // console.log('sw_control: ', sw_control)
      // console.log('tm_control: ', tm_control)

      if (typeof settings !== 'undefined') {
         if (settings === false) {
            setTM_control(0);
            setSW_control(0);
         }
         if (settings.reverse) {
            setClockType('timer');
            if (settings.id !== null) {
               const time = new Date();
               const expiry = time.setSeconds(time.getSeconds() + settings.value)
               setTM_expiry(expiry);
               setTM_control(settings.state);
            }
         } else {
            setClockType('stopwatch');
            if (settings.id !== null) {
               const stopwatchOffset = new Date();
               const offsetTimestamp = stopwatchOffset.setSeconds(stopwatchOffset.getSeconds() + settings.value);
               setSW_offSet(offsetTimestamp);
               setSW_control(settings.state);
            }
         }
      }
   }

   useMemo(() => {
      return updateClock(Props.settings)
   }, [Props.settings])


   return (
      <div className={style.wrap} style={{ display: Props.hidden ? 'none' : '' }} >

         {/* {console.log('Clokc: ', Props.hidden)} */}

         {clockType === 'stopwatch' ? (
            <StopWatch offSet={sw_offSet} control={sw_control} callback={(total) => { tick_callback(total, 'SW') }} ></StopWatch>
         ) : (
            <Timer expiry={tm_expiry} control={tm_control} callback={(total) => { tick_callback(total, 'TM') }} ></Timer>
         )}
      </div>
   );
}



export default Clock;