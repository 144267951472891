import React, { useMemo, useEffect } from 'react';
import { useTimer } from 'react-timer-hook';
import style from './clock-style.module.scss';

function Timer({ expiry, control, callback }) {
   const {
      totalSeconds,
      seconds,
      minutes,
      // hours,
      // days,
      // isRunning,
      start,
      pause,
      // resume,
      restart,
   } = useTimer({ expiryTimestamp: expiry, onExpire: () => console.warn('onExpire called') });

   const updateTimer = (control) => {
      // console.log('>>>--- updateTimer: ', control);
      if (typeof control !== 'undefined') {
         if (control === 1) {
            // console.error('START Timer');
            restart(expiry);
            start()
         } else {
            // console.error('STOP Timer');
            const time = new Date();
            const zero_expiry = time.setSeconds(time.getSeconds());
            restart(zero_expiry);
            pause();
         }
      }
   }

   useMemo(() => {
      return updateTimer(control)
   }, [control])

   useEffect(() => {
      if (control === 1 && typeof callback === 'function') {
         callback(totalSeconds);
      }
   }, [totalSeconds]);

   return (
      <div className={style.wrap}>
         <span>{('0'+minutes).slice(-2)}</span>:<span>{('0'+seconds).slice(-2)}</span>
      </div>
   );
}

export default Timer; 