'use strict'
import React from 'react';
import reactCSS from 'reactcss';
import { ChromePicker } from 'react-color';
import style from './color-box-style.module.scss';

class ColorBox extends React.Component {
   state = {
      displayColorPicker: false,
      color: {},
      name: {},
   };

   constructor(Props) {
      super();

      if (typeof Props.defaultColor !== 'undefined') {
         this.state.color = Props.defaultColor;
      }
      if (typeof Props.name !== 'undefined') {
         this.state.name = Props.name;
      }
   }

   handleChange = (color) => { this.setState({ color: color.hex }) };
   handleClose = () => { this.setState({ displayColorPicker: false }) };
   handleClick = () => { this.setState({ displayColorPicker: !this.state.displayColorPicker }) };

   render() {
      const styles = reactCSS({
         'default': {
            color: {
               width: '30px',
               height: '30px',
               boxShadow: '0 0 3px 0 var(--white)',
               borderRadius: 'var(--base-border-radius)',
               background: this.state.color,
            },
            swatch: {
               cursor: 'pointer',
            },
            popover: {
               position: 'absolute',
               zIndex: '2',
               display: 'flex',
               top: '0',
               left: '0',
               alignItems: 'center',
               justifyContent: 'center',
               width: '100vw',
               height: '100vh',
               background: '#00000042',
            },
            cover: {
               position: 'fixed',
               left: '0px',
               top: '0px',
               bottom: '0px',
               right: '0px',
               background: '#00000042',
            },
         },
      });

      return (
         <div className={style.wrap}>
            <div style={styles.swatch} onClick={this.handleClick}>
               <div style={styles.color} />
            </div>
            {this.state.displayColorPicker ? <div style={styles.popover}>
               <div style={styles.cover} onClick={this.handleClose} />
               <ChromePicker disableAlpha={true} color={this.state.color} onChange={this.handleChange} />
            </div> : null}
            <input type="hidden" name={this.state.name} value={this.state.color} />
         </div>
      )
   }
}

export default ColorBox