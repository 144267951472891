import React from 'react';
function Head(Props) {
  return (
    <div className="head">
      {Props.children}
   </div>
  );
}

export default Head;
