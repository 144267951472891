import React, { useEffect, useState, useRef } from 'react';
import { useIsVisible } from 'react-is-visible';
import RemoteImage from '../remote_img/remote_img';
import { Loop_Timer } from '../../javascript/utils/utils';
import DBHandler from '../../javascript/index-db/db-handler';
import style from './ad-image-style.module.scss';

const dbHandler = DBHandler();
var image_list = [];

function AdImage() {
   const wrapper = useRef();
   const isVisible = useIsVisible(wrapper);
   const [image, setImage] = useState(() => '');
   const [ad_image_timer, _setADTimer] = useState(() => new Loop_Timer('ad_image_timer', 15));
   
   const load_images = () => {
      return new Promise(async (resolve) => {
         const club = await dbHandler.load_club();
         if (typeof club !== 'undefined') {
            const list = [
               {
                  img: club.ad_1.substring(3),
                  timer: 15,
               },
               {
                  img: club.ad_2.substring(3),
                  timer: 10,
               },
               {
                  img: club.ad_3.substring(3),
                  timer: 5,
               },
               {
                  img: club.club_logo,
                  timer: 5,
               },
            ];
            image_list = list;
            resolve(true);
         }
      })
   }
   
   const init = async () => {
      var img_index = 1;
      const cycle_ad = () => {
         if (typeof image_list[img_index] !== 'undefined') {
            setImage(image_list[img_index].img);
            ad_image_timer.set_interval(image_list[img_index].timer);
         }
         img_index++;
         if (img_index > image_list.length-1) {
            img_index = 0;
         }
      }
      if (image_list.length === 0) { await load_images(); }
      if (typeof image_list[0] !== 'undefined') {
         setImage(image_list[0].img);
         ad_image_timer.set_interval(image_list[0].timer);
      }
      ad_image_timer.start(() => { cycle_ad(); });
   }

   useEffect(() => {
      if (isVisible === true) {
         init();
      } else {
         ad_image_timer.stop();
      }
   }, [isVisible]);

   return (<div className={style.wrap} ref={wrapper}><RemoteImage src={image} /></div>);
}

export default AdImage;