import $ from 'jquery';
import dayjs from "dayjs";
import Swal from 'sweetalert2';
import Auth from "../services/auth";
import { LOCKS } from "../services/globalVars";
import DBHandler from "../index-db/db-handler";
import { open_login } from "../sport/sport-function";
import withReactContent from 'sweetalert2-react-content';

const getRandomInt = (min, max) => {
   min = Math.ceil(min);
   max = Math.floor(max);
   return Math.floor(Math.random() * (max - min + 1) + min);
}

const Timestamp = () => {
   return dayjs().valueOf();
}

const genKeyRan = (tag = '') => {
   return `${tag}-${getRandomInt(10000, 99999)}`
}

const genRanID = () => {
   return `ID${getRandomInt(10000000, 99999999)}`
}

const Date_Convert_Timezone = (date, tzString = 'Etc/GMT') => {
   return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", { timeZone: tzString }));
}

const Timestamp_to_String = (timestamp = null) => {
   console.log('>>> Timestamp_to_String <<<');
   const date = dayjs.unix(timestamp);
   // console.log('date: ', date.format('MMM D h:m'));
   return date.format('MMM D h:m:s');
}

const Get_Date = () => {
   // let date = new Date();
   // const result_1 = date.getTime();
   const result_1 = dayjs().valueOf();

   // date = Date_Convert_Timezone(date, 'Etc/GMT');
   // const result_2 = date.getTime();
   // console.log('result_1: ', result_1);
   // console.log('result_2: ', result_2);

   return result_1;
}

const Debounce = (callback, wait) => {
   let timeoutId = null;
   return (...args) => {
      window.clearTimeout(timeoutId);
      timeoutId = window.setTimeout(() => {
         callback(...args);
      }, wait);
   };
}

const CloseAllSwal = () => {
   Swal.close();
}


const ConfirmModel = ({
   icon = '',
   html = '',
   title = '',
   text = '',
   position = 'center',
   showCancelButton = true,
   showConfirmButton = true,
   allowOutsideClick = true,
   showDenyButton = false,
   showCloseButton = false,
   showLoaderOnConfirm = false,
   cancelButtonText = 'Cancel',
   confirmButtonText = 'OK',
   denyButtonText = 'No',
   confirmButtonColor = 'var(--green)',
   cancelButtonColor = 'var(--red)',
   denyButtonColor = 'var(--red)',
   imageUrl,
   imageWidth,
   imageHeight,
   imageAlt = '',
   width = '32em',
   preConfirm = false,
   grow = false,
   footer = '',
   css_container = 'swalContainer',
   css_popup = 'swalPopup',
   css_HtmlContainer = '...',
   css_title = '...',
}) => {
   return new Promise(async (resolve, reject) => {
      try {
         const result = await withReactContent(Swal).fire({
            icon: icon,
            html: html,
            text: text,
            title: title,
            position: position,
            imageUrl: imageUrl,
            imageAlt: imageAlt,
            imageWidth: imageWidth,
            imageHeight: imageHeight,
            showCancelButton: showCancelButton,
            showConfirmButton: showConfirmButton,
            showDenyButton: showDenyButton,
            allowOutsideClick: allowOutsideClick,
            cancelButtonText: cancelButtonText,
            denyButtonText: denyButtonText,
            confirmButtonColor: confirmButtonColor,
            cancelButtonColor: cancelButtonColor,
            denyButtonColor: denyButtonColor,
            confirmButtonText: confirmButtonText,
            showCloseButton: showCloseButton,
            preConfirm: preConfirm,
            showLoaderOnConfirm: showLoaderOnConfirm,
            footer: footer,
            width: width,
            grow: grow,
            customClass: {
               container: css_container,
               popup: css_popup,
               header: '...',
               title: css_title,
               closeButton: '',
               icon: '...',
               image: '...',
               htmlContainer: css_HtmlContainer,
               input: '...',
               inputLabel: '...',
               validationMessage: '...',
               actions: '...',
               confirmButton: 'swalConfirmButton',
               denyButton: 'swalDenyButton',
               cancelButton: 'swalCancelButton',
               loader: '...',
               footer: '....',
               timerProgressBar: '....',
            }
         })


         resolve(result);
      } catch (error) {
         // throw 'ConfirmModel Error:'+ error;
         resolve(false);
         // reject('ConfirmModel Error: ', error)
      }
   });
}

const object_pathfinder = (Obj, key, path = []) => {
   let result = false;
   // console.log('object_pathfinder: '+key , path)
   for (const baseKey in Obj) {
      if (Object.hasOwnProperty.call(Obj, baseKey)) {
         if (baseKey === key) {
            path.push(baseKey);
            return path;
         } else {
            if (typeof Obj[baseKey] === 'object') {
               const newPath = JSON.parse(JSON.stringify(path));
               newPath.push(baseKey);
               result = object_pathfinder(Obj[baseKey], key, newPath);
               if (result !== false) {
                  return result;
               }
            }
         }
      }
   }
   return result;
}

const object_merge = (obj, links, dataSplice, data) => {
   const link = links.shift();
   obj = {
      ...dataSplice,
      [link]: 'chain_link',
   };
   if (links.length > 0) {
      let nextRow = object_merge(obj[link], links, data);
      obj[link] = nextRow;
   } else {
      obj[link] = data;
   }
   return obj;
}

const MergeObjects = (object, link, data) => {
   let result = false;
   const linkPath = object_pathfinder(object, link);
   if (linkPath !== false) {
      const root = linkPath.shift();
      const mergedData = object_merge({}, linkPath, object[root], data);
      result = {
         ...object,
         [root]: mergedData,
      }
   }
   return result;
}

const Sleep = (timer = 1000, callback = null, repeat = 0) => {
   return new Promise((resolve) => {
      setTimeout(function () {
         if (callback !== null) {
            let result = callback();
            if (result === false && repeat > 0) {
               repeat--;
               Sleep(timer, callback, repeat).then((result) => {
                  resolve(result);
               });
            } else if (result === true) {
               resolve(true);
            } else {
               resolve(false);
            }
         } else {
            resolve(true);
         }
      }, timer);
   });
}


const Get_Device_Type = () => {
   let device = "Unknown";
   const ua = {
      "Generic Linux": /Linux/i,
      "Android": /Android/i,
      "BlackBerry": /BlackBerry/i,
      "Bluebird": /EF500/i,
      "Chrome OS": /CrOS/i,
      "Datalogic": /DL-AXIS/i,
      "Honeywell": /CT50/i,
      "iPad": /iPad/i,
      "iPhone": /iPhone/i,
      "iPod": /iPod/i,
      "macOS": /Macintosh/i,
      "Windows": /IEMobile|Windows/i,
      "Zebra": /TC70|TC55/i,
   }
   Object.keys(ua).map(v => navigator.userAgent.match(ua[v]) && (device = v));

   // window.navigator.platform

   return device;
}


const AdjustFontSize = (args = { loop_max: 200, font_step: 0.5, font_max: 40 }) => {
   const LOOP_MAX = typeof args.loop_max === 'number' ? args.loop_max : 200;
   const FONT_STEP = typeof args.font_step === 'number' ? args.font_step : 0.5;
   const FONT_MAX = typeof args.font_max === 'number' ? args.font_max : 40;

   const loop_handler = (count, condition, action) => {
      for (let a = 0; a < count; a++) {
         action();
         if (condition()) {
            break;
         }
      }
   }

   const isOverLapping = (main, sub) => {
      const mainWidth = $(main).width();
      const subWidth = $(sub).width();

      // const subFont = parseFloat($(sub).css('font-size'));
      // if (subFont >= FONT_MAX) { return false; } 

      if (subWidth > mainWidth) {
         return true;
      }
      return false;
   }

   const isUnderLapping = (main, sub) => {
      const mainWidth = $(main).width();
      const subWidth = $(sub).width();
      const subFont = parseFloat($(sub).css('font-size'));
      if (subFont >= FONT_MAX) {
         return false;
      }
      if ((subWidth) < mainWidth) {
         return true;
      }
      return false;
   }


   const decreaseFont = (element) => {
      const fontSize = $(element).css('font-size');
      const fontSizeFloat = parseFloat(fontSize);
      const newFontSize = (fontSizeFloat - FONT_STEP);
      $(element).css('font-size', newFontSize + 'px');
   }
   const increaseFont = (element) => {
      const fontSize = $(element).css('font-size');
      const fontSizeFloat = parseFloat(fontSize);
      const newFontSize = (fontSizeFloat + FONT_STEP);
      $(element).css('font-size', newFontSize + 'px');
   }

   $('.nameWrap').each((index, elm) => {
      $(elm).find('span').each((_, subElm) => {
         if (isOverLapping(elm, subElm)) {
            loop_handler(
               LOOP_MAX,
               () => { return !isOverLapping(elm, subElm) },
               () => { return decreaseFont(subElm) },
            )
         } else if (isUnderLapping(elm, subElm)) {
            loop_handler(
               LOOP_MAX,
               () => { return !isUnderLapping(elm, subElm) },
               () => { return increaseFont(subElm) },
            )
         }
      });
   });
}


const FormatSeconds = (time_seconds) => {
   const hours = ('0' + Math.floor(time_seconds / 60 / 60 % 60)).slice(-2);
   const minutes = ('0' + Math.floor(time_seconds / 60 % 60)).slice(-2);
   const seconds = ('0' + parseInt(time_seconds % 60)).slice(-2);
   return hours > 0 ? hours + ':' + minutes + ':' + seconds : minutes + ':' + seconds;
}


const Wait_For_Auth = (authObj, open_login_on_fail = false) => {
   return new Promise(async (resolve) => {
      const authResult = await authObj.init();
      if (open_login_on_fail && authResult === false) {
         const token = await authObj.get_token();
         if (typeof token.refreshToken === 'undefined' || token.refreshToken === null) {
            open_login();
         }
         await auth_check(authObj);
      }
      resolve(true)
   });
}

const auth_check = (authObj) => {
   return new Promise(async (resolve) => {
      let authResult = false;
      do {
         await Sleep(2000);
         authResult = await authObj.init();
         console.warn('>>>> is Logged in: ', authResult);
      } while (authResult === false);
      resolve(true);
   });
}


const Logout = () => {
   return new Promise(async (resolve) => {
      try {
         const authObj = Auth();
         authObj.logout().then(async (result) => {
            const db_handler = DBHandler();
            await db_handler.delete_account();
            await db_handler.delete_club();
            await db_handler.delete_sync();
            if (result) {
               ConfirmModel({
                  icon: 'success',
                  title: `Your now logged out`,
                  confirmButtonText: 'Ok',
                  showCancelButton: true,
                  allowOutsideClick: true,
                  showCancelButton: false,
               }).then(() => {
                  db_handler.reset_database().then(() => {
                     window.location = '/';
                  });
               });
               resolve(true);
            } else {
               resolve(false);
            }
         }).catch(() => {
            resolve(false);
         })
      } catch (error) {
         resolve(false);
      }
   });
}


// const lock = new WeakMap();
// const UsingLock = async (resource, borrow) => {
//    while (lock.has(resource)) {
//       try {
//          await lock.get(resource);
//       } catch { }
//    }
//    // acquire
//    lock.set(resource, borrow());
//    try {
//       return await lock.get(resource);
//    } finally {
//       // release
//       lock.delete(resource);
//    }
// }
const UsingLock = async (resource, borrow) => {
   while (LOCKS.lock.has(resource)) {
      try {
         await LOCKS.lock.get(resource);
      } catch { }
   }
   // acquire
   LOCKS.lock.set(resource, borrow());
   try {
      return await LOCKS.lock.get(resource);
   } finally {
      // release
      LOCKS.lock.delete(resource);
   }
}



class Loop_Timer {
   id = '';
   total_seconds = 0;
   interval = 1000;
   clock = null;

   constructor(name = 'Loop_Timer', interval = 1000) {
      this.id = genKeyRan(name);
      if (typeof interval === 'number') {
         this.interval = interval;
      }
   }

   start = (callback = null) => {
      this.stop();
      var self = this;
      this.total_seconds = 0;
      // console.log('Loop_Timer - start: ', this.id);
      this.clock = setInterval(function () {
         self.total_seconds += 1;
         if (self.total_seconds / self.interval % 1 === 0) {
            if (typeof callback === 'function') {
               // console.log('Timer - interval:', self.interval);
               // console.log('Timer - total_seconds:', self.total_seconds);
               self.total_seconds = 0;
               callback(self.total_seconds);
            }
         }
      }, 1000);
   }

   stop = () => {
      // console.log('Loop_Timer - stop: ', this.id);
      clearInterval(this.clock);
      this.total_seconds = 0;
   }

   set_interval = (interval) => {
      if (typeof interval === 'number') {
         this.interval = interval;
      }
   }

}



export {
   Sleep,
   Logout,
   Get_Date,
   genRanID,
   Debounce,
   UsingLock,
   genKeyRan,
   Timestamp,
   MergeObjects,
   ConfirmModel,
   CloseAllSwal,
   FormatSeconds,
   Wait_For_Auth,
   AdjustFontSize,
   Get_Device_Type,
   Timestamp_to_String,
   Loop_Timer
};