import React, { useEffect, useState, useMemo } from 'react';
import Clock from '../../clock/clock.js';
import AdImage from '../../ad-image/ad-image';
import Overlay from '../../overlay-wrap/overlay-wrap';
import RemoteImage from '../../remote_img/remote_img.js';
import ScoreTotals from '../../score-totals/score-totals.js';
import ScoreToolBlock from '../score-block/toolblock/toolblock.js';
import { SPORT_BASE, GLOBAL_CSS } from '../../../javascript/services/globalVars.js';
import DBHandler from '../../../javascript/index-db/db-handler.js';
import style from './end-game-block-style.module.scss';

const dbHandler = DBHandler();

function EndGameBlock(Props) {
   const [btnList, setBtnList] = useState([]);
   const [matchWon, setMatchWon] = useState(false);
   const [clubIMG, setClubIMG] = useState(() => '');
   // const [endGameClock, setEndGameClock] = useState();
   // const [update, setUpdate] = useState(0);

   let visibility = 0;
   if (typeof Props.visibility !== 'undefined') {
      visibility = Props.visibility;
   }

   const load_match = async () => {
      if (visibility === 1) {
         const match_won = SPORT_BASE.sport_object.scoring.scoring.match_won;
         const tools = await SPORT_BASE.sport_object.get_tool_block('between_games');
         setMatchWon(match_won);
         setBtnList(tools);
      }
   }

   const onBtnPress = (value) => {
      if (typeof value.type !== 'undefined') {
         if (value.type === 'setTimer') {
            // setUpdate(v => v + 1);
         }
      }
   }

   useEffect(() => {
      load_match();
      // >>> Get Club Image
      dbHandler.load_club().then((account_result) => {
         if (typeof account_result !== 'undefined' && account_result.club_logo !== '') {
            setClubIMG(account_result.club_logo);
         }
      });
   }, [visibility]);

   return (
      <Overlay visibility={visibility}>
         <div className={style.wrap} style={GLOBAL_CSS.background} >
            <div className={style.ScoreTally}><ScoreTotals refresh={visibility}></ScoreTotals></div>
            <div className={style.AddRow}>
               <div className={style.imgWrap} >
                  <RemoteImage className={style.leftImg} src={clubIMG} />
               </div>
               {matchWon ? (
                  <div className={style.imgWrap} >Match Completed</div>
               ) : (
                  <div className={style.clockBox}> <Clock settings={Props.timer} ></Clock> </div>
               )}
               <AdImage />
            </div>

            <div className={style.BtnRow} >
               <ScoreToolBlock btnList={btnList} onBtnPress={onBtnPress} ></ScoreToolBlock>
            </div>
         </div>
      </Overlay>
   );
}

export default EndGameBlock;