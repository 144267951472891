import React from 'react';


import style from './scoring-foot-style.module.scss';


function ScoringFoot(Props) {
   // console.log('>> ScoringFoot: ', Props)

   let styleHeight = '';
   if (Props.cssHeight === '20') {
      styleHeight = style.hight20;
   }

   return (
      <div className={`${style.foot} ${styleHeight}`}>
         {Props.children}
      </div>
   );
}

export default ScoringFoot;